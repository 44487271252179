import type { PauseSubscriptionInput, PauseSubscriptionPayload } from '@market/graphql/schema/graphql'
import { pauseSubscriptionMutation } from '@market/graphql/mutations'
import { MutationTuple, useMarketMutation } from './useMarketMutation'

export type PauseSubscriptionMutationData = { pauseSubscription: PauseSubscriptionPayload }

export const usePauseSubscription: (variables?: PauseSubscriptionInput) => MutationTuple<PauseSubscriptionMutationData, PauseSubscriptionInput> = (variables)  => {
  const [ pauseSubscriptionFn, { data, ...result } ] = useMarketMutation<PauseSubscriptionMutationData, PauseSubscriptionInput>(
    pauseSubscriptionMutation,
    {
      variables,
    }
  )

  return [ pauseSubscriptionFn, { ...result, data } ]
}

export default usePauseSubscription
