import React, { useCallback, useState } from 'react'
import {
  Box,
  Button,
  IconButton,
  Text,
  Dialog,
  DialogTitle,
  DialogContent,
} from 'components'
import { useViewport } from 'hooks'
import {
  Add as AddIcon,
  ArrowBack,
  Close as CloseIcon,
} from 'icons'
import { AccountUser } from '@market/graphql/schema/graphql'
import { useCreateAccountUserForm, useCurrentAccount } from '@market/hooks'
import { AccountUserForm } from '@market/components/AccountUserForm'

export interface CreateAccountUserCardProps {
  accountUser?: AccountUser
}

export const CreateAccountUserCard: React.FC<CreateAccountUserCardProps> = () => {
  const { isMedium } = useViewport()
  const { account, defaultLocation } = useCurrentAccount()
  const [ modalOpen, setModalOpen ] = useState<boolean>(false)

  const openModal = useCallback(() => {
    setModalOpen(true)
  }, [setModalOpen])

  const closeModal = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  const [ mutationForm, { errors, loading, submitted } ] = useCreateAccountUserForm(account, defaultLocation, { onSuccess: () => closeModal() })

  return <Box sx={{ cursor: 'pointer', width: '100%', minWidth: '100%', height: 'auto', aspectRatio: '1/1' }}>
    <Box display="flex" flexDirection="column" alignItems="stretch" justifyContent="stretch" onClick={() => openModal()} sx={(theme) => ({
      width: '100%',
      height: 'auto',
      aspectRatio: '1/1',
      cursor: 'pointer',
      borderRadius: '10px',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.palette.divider,
    })}>
      <Box margin="auto">
        <AddIcon fontSize="large" />
      </Box>
    </Box>

    <Dialog open={!!modalOpen} fullScreen={!isMedium} onClose={closeModal} maxWidth="sm" fullWidth>
      <DialogTitle display="flex" flexWrap="nowrap" gap={2} sx={{ alignItems: { md: 'center' } }}>
        { !isMedium && <Box>
          <Button variant="text" onClick={() => closeModal()}><ArrowBack /></Button>
        </Box> }

        <Box display="flex" alignItems="center" gap={2}>
          <Text variant="h6">Add family member</Text>
        </Box>

        { isMedium && <Box ml="auto">
          <IconButton onClick={() => closeModal()}><CloseIcon /></IconButton>
        </Box> }
      </DialogTitle>

      <DialogContent sx={{ padding: 0 }}>
        <Box py={2} px={2}>
          <AccountUserForm form={mutationForm} errors={errors} loading={loading} submitted={submitted} />
        </Box>
      </DialogContent>
    </Dialog>
  </Box>
}

export default CreateAccountUserCard
