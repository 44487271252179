import React, { useCallback } from 'react'
import {
  Box,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Text,
} from 'components'
import { SetFilters, Filters, FilterOnChange, FilterOption } from 'hooks/useFilters'

export interface CheckboxFilterProps<T extends object, D extends object, K extends keyof T = keyof T> {
  filterName: string,
  filterDefinitions: Filters<T, D, K>,
  filters: T,
  row?: boolean
  hideLabel?: boolean
  disableOption?: (option: FilterOption) => boolean
  setFilters: SetFilters<T>
}

export function CheckboxFilter<T extends object, D extends object, K extends keyof T = keyof T>(props: CheckboxFilterProps<T, D, K>) {
  const { filterName, filterDefinitions, filters, row, hideLabel, disableOption, setFilters } = props
  const filterDefinition = filterDefinitions.find((definition) => definition.name === filterName)

  const onChange: FilterOnChange<object> = useCallback((value, filters, checked) => {
    const withoutOption = filters[filterName].filter((val: string) => val !== value)
    if (checked) {
      return { [filterName]: [ ...withoutOption, value ] }
    } else {
      return { [filterName]: withoutOption }
    }
  }, [filterName])

  return <Box width="100%" pb={4}>
    { !hideLabel && <Text variant="subtitle1" fontWeight="medium" pb={2}>{ filterDefinition.label }</Text> }

    { (!filterDefinition.options || filterDefinition.options?.length === 0) && <Text variant="caption">No filter options found in your current current search. Try expanding your other filters.</Text> }
    <FormGroup row={row}>
      { filterDefinition.options?.map((option) =>
        <FormControlLabel key={option.value} label={`${option.key}${option.count >= 0 ? ` (${option.count})` : ''}`} control={
          <Checkbox
            checked={filters[filterName].includes(option.value)}
            disabled={option.disabled || (!!disableOption && disableOption(option))}
            onChange={(_e, checked) => {
              setFilters((filterDefinition.onChange || onChange)(option.value, filters, checked))
            }}
          />
        } />
      ) }
    </FormGroup>
  </Box>
}

export default CheckboxFilter
