import lightGraphite from './lightGraphite'
import darkGraphite from './darkGraphite'

export const graphite = {
  50: '#f7f7f7',
  100: '#eeeeee',
  200: '#e2e2e2',
  300: '#d0d0d0',
  400: '#ababab',
  500: '#8a8a8a',
  600: '#636363',
  700: '#505050',
  800: '#323232', // primary
  900: '#121212',
  A100: '#eeeeee',
  A200: '#e2e2e2',
  A400: '#ababab',
  A700: '#505050',
}

export const palette = {
  main: graphite[800],
  // light: lightGraphite[200],
  // dark: darkGraphite[900],
  // contrastText: '#ffffff',
}

export { lightGraphite, darkGraphite }
export default graphite
