import { gql } from '@market/graphql/schema/gql'

export const cancelSubscriptionMutation = gql(`
  mutation cancelSubscriptionMutation($input: CancelSubscriptionInput! ) {
    cancelSubscription(input: $input) {
      subscription {
        ...SubscriptionFields
      }
      errors {
        code
        message
        path
      }
    }
  }
`)

export default cancelSubscriptionMutation
