import React from 'react'
import MenuItem from '@mui/material/MenuItem'
import { MenuLink } from 'components'
import { useMe, useLoginUrl } from 'hooks'
import { Kitchen as KitchenIcon } from 'icons'

export type KitchenLinkProps = {
  me: ReturnType<typeof useMe>
  onClick?: React.MouseEventHandler
}

export const KitchenLink: React.FC<KitchenLinkProps> = ({ me, onClick }) => {
  const kitchenLoginUrl = useLoginUrl('kitchen')

  if (!me.hasKitchenAccess) return null

  return <MenuItem onClick={onClick}>
    <MenuLink href={kitchenLoginUrl} sx={(theme) => ({ fontWeight: 'bold', color: theme.palette.kitchen.dark })}>
      <KitchenIcon sx={{ marginRight: 1 }} />
      Kitchen Manager
    </MenuLink>
  </MenuItem>
}

export default KitchenLink
