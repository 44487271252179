import { gql } from '@market/graphql/schema/gql'

export const submitHelpRequestMutation = gql(`
  mutation submitHelpRequestMutation($input: SubmitHelpRequestInput!) {
    submitHelpRequest(input: $input) {
      requestId
      errors {
        code
        message
      }
    }
  }
`)

export default submitHelpRequestMutation
