import React from 'react'
import MuiBadge, { BadgeProps } from '@mui/material/Badge'

export type { BadgeProps }

declare module '@mui/material/Badge' {
  interface BadgePropsColorOverrides {
    campus: true
    kitchen: true
    kiosk: true
    luxo: true
    market: true
    tools: true
  }
}

export const Badge = <C extends React.ElementType='span'>(props: BadgeProps<C>) => <MuiBadge {...props} />

export default Badge
