import { gql } from '@market/graphql/schema/gql'

export const archiveAccountUserMutation = gql(`
  mutation archiveAccountUserMutation($input: ArchiveAccountUserInput! ) {
    archiveAccountUser(input: $input) {
      accountUser {
        ...AccountUserFields
      }
      errors {
        code
        message
        path
      }
    }
  }
`)

export default archiveAccountUserMutation
