import { gql } from '@market/graphql/schema/gql'

export const pauseSubscriptionMutation = gql(`
  mutation pauseSubscriptionMutation($input: PauseSubscriptionInput! ) {
    pauseSubscription(input: $input) {
      subscription {
        ...SubscriptionFields
      }
      errors {
        code
        message
        path
      }
    }
  }
`)

export default pauseSubscriptionMutation
