import lightTeal from './lightTeal'
import darkTeal from './darkTeal'

export const teal = {
  50: '#e1f5fe',
  100: '#b3e5fc',
  200: '#81d4fa',
  300: '#4fc3f7',
  400: '#2ab7f6',
  500: '#04aaf4',
  600: '#049ce5',
  700: '#0289d1', // primary
  800: '#0278bd',
  900: '#01589b',
  A100: '#b3e5fc',
  A200: '#81d4fa',
  A400: '#2ab7f6',
  A700: '#0289d1',
}

export const palette = {
  main: teal[700],
  // light: lightTeal[500],
  // dark: darkTeal[900],
  // contrastText: '#ffffff',
}

export { lightTeal, darkTeal }
export default teal
