import React from 'react'
import MenuItem from '@mui/material/MenuItem'
import { MenuLink } from 'components'
import { useMe, useLoginUrl } from 'hooks'
import { Campus as CampusIcon } from 'icons'

export type CampusLinkProps = {
  me: ReturnType<typeof useMe>
  onClick?: React.MouseEventHandler
}

export const CampusLink: React.FC<CampusLinkProps> = ({ me, onClick }) => {
  const campusLoginUrl = useLoginUrl('campus')

  if (!me.hasCampusAccess) return null

  return <MenuItem onClick={onClick}>
    <MenuLink href={campusLoginUrl} sx={(theme) => ({ fontWeight: 'bold', color: theme.palette.campus.dark })}>
      <CampusIcon sx={{ marginRight: 1 }} />
      School Admin
    </MenuLink>
  </MenuItem>
}

export default CampusLink
