import type {} from '@mui/x-date-pickers-pro/themeAugmentation'
import { deepmerge } from '@mui/utils'
import { createTheme, ThemeOptions } from '@mui/material/styles'
import { options as baseOptions } from 'theme'

export const options: ThemeOptions = {}

export const theme = createTheme(deepmerge(baseOptions, options))

export default theme
