import React from 'react'
import { DateTime } from 'luxon'
import { Box, Button, Text } from 'components'
import { useOrderModal } from '@market/hooks/useOrderModal'
import { Order } from '@market/graphql/schema/graphql'
import { ProductQuantityCard } from '../ProductQuantityCard'
import { OrderModal } from '../OrderModal'
import { OrderStatus } from '../OrderStatus'
import { styled } from '@mui/material'

const QuantityCards = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 0,
  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(2),
  },
}))

export const OrderSummary: React.FC<{ order: Order, interactive?: boolean }> = ({ order, interactive=true }) => {
  const { open, openModal, closeModal } = useOrderModal({ order })

  return <Box mb={8}>
    <OrderModal order={order} open={open} onClose={() => closeModal()} />

    <Box display="flex" flexWrap="nowrap" alignItems="center" gap={2} mb={2} px={{ xs: 1, sm: 2, md: 0}}>
      <Text variant="h6" fontWeight="normal">{ DateTime.fromISO(order.deliveryDate).toFormat('ccc, LLL d') }</Text>

      <OrderStatus order={order} />

      <Text variant="h6">{ order.recipient.preferredName || order.recipient.firstName }</Text>

      { interactive && <><Text variant="h6" fontWeight="normal" color="gray">|</Text>
        <Button fullWidth={false} variant="text" size="small" onClick={openModal}>Order details</Button></> }
    </Box>

    <QuantityCards className="OrdoOrderSummary-cards">
      { order.orderProducts.filter((orderProduct) => orderProduct.topLevel || orderProduct.standalone).map((orderProduct) =>
        <ProductQuantityCard
          key={orderProduct.id}
          product={orderProduct.menuProduct.product}
          quantity={orderProduct.quantity}
          onClick={interactive ? openModal : () => (null)}
          sx={{
            cursor: interactive ? 'pointer' : undefined,
            opacity: orderProduct.computedStatus === "CANCELLED" ? 0.3 : 1,
            '&:hover': {
              opacity: 1,
            }
          }}
        />
      ) }
    </QuantityCards>
  </Box>
}

export default OrderSummary