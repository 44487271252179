import lightGreen from './lightGreen'
import darkGreen from './darkGreen'

export const green = {
  50: '#e7f6ec',
  100: '#c5e8d1',
  200: '#9fd9b5',
  300: '#77cb97',
  400: '#58c081',
  500: '#36b56c',
  600: '#2ea561',
  700: '#259355', // primary
  800: '#1e8249',
  900: '#116235',
  A100: '#c5e8d1',
  A200: '#9fd9b5',
  A400: '#58c081',
  A700: '#259355',
}

export const palette = {
  main: green[700],
  // light: lightGreen[100],
  // dark: darkGreen[900],
  // contrastText: '#ffffff',
}

export { lightGreen, darkGreen }

export default green
