import React, { useMemo } from 'react'
import { useField, FieldProps } from 'formik'
import { AsYouType } from 'libphonenumber-js'
import { TextField, TextFieldProps } from 'components/TextField'

type FormTelephoneFieldProps = Partial<FieldProps> & TextFieldProps

export const FormTelephoneField: React.FC<FormTelephoneFieldProps> = ({ name, helperText, InputLabelProps, ...props }) => {
  const [ { value, ...field }, meta, helpers ] = useField(name)
  const { touched, error } = meta
  const formatted = useMemo(() => {
    if (value.length === 0 || value.length > 3) {
      return new AsYouType("US").input(value)
    } else {
      return value
    }
  }, [ value ])

  return <TextField
    {...props}
    autoComplete="off"
    error={touched && !!error}
    helperText={(touched && error) || helperText}
    InputLabelProps={{ shrink: true, ...InputLabelProps }}
    {...field}
    onChange={(event) => {
      const numeric = event.currentTarget.value.replaceAll(/[\D]/g, '')
      helpers.setValue(numeric.slice(0, 10))
    }}
    value={formatted}
  />
}

export default FormTelephoneField
