import React, { useState } from 'react'
import { DateTime } from 'luxon'
import {
  Box,
  Button,
  IconButton,
  Text,
  ConfirmDialog,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Currency,
} from 'components'
import {
  ArrowBack,
  Close as CloseIcon,
} from 'icons'
import { useViewport } from 'hooks'
import { Order } from '@market/graphql/schema/graphql'
import { useCancelOrder } from '@market/hooks'
import { OrderStatus } from '../OrderStatus'
import { OrderItem } from './OrderItem'

export const OrderModal: React.FC<{ order: Order, open: boolean, onClose: () => void }> = ({ order, open, onClose }) => {
  const { isMedium } = useViewport()
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [cancelOrder, { loading }] = useCancelOrder()

  return <Dialog open={!!open} fullScreen={!isMedium} onClose={onClose} maxWidth="sm" fullWidth>
    <ConfirmDialog
      open={confirmDialogOpen}
      onClose={() => setConfirmDialogOpen(false)}
      content={<Text>Are you sure you want to cancel this order?</Text>}
      action={<Button color="error" disabled={loading || !confirmDialogOpen} onClick={() => {
        cancelOrder({ variables: { id: order.id } })
        setConfirmDialogOpen(false)
      }}>Cancel order</Button>}
    />

    <DialogTitle>
      <Box display="flex" flexWrap="nowrap" gap={2} sx={{ alignItems: { md: 'center' } }}>
        { !isMedium && <Box>
          <Button variant="text" onClick={() => onClose()}><ArrowBack /></Button>
        </Box> }

        <Box>
          <Text variant="h5">{ order.recipient.displayName }</Text>
        </Box>

        { isMedium && <Box ml="auto">
          <IconButton onClick={() => onClose()}><CloseIcon /></IconButton>
        </Box> }
      </Box>

      <Box display="flex" alignItems="center" gap={2}>
        <Text variant="h6" fontWeight="normal">{ DateTime.fromISO(order.deliveryDate).toFormat('ccc, LLL d') }</Text>
        <OrderStatus order={order} ml="auto" />
      </Box>
    </DialogTitle>

    <DialogContent sx={{ padding: { xs: 0, md: 2 } }}>
      <Box sx={(theme) => ({
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
        borderTopColor: theme.palette.divider,
      })}>
        { order.orderProducts.filter((orderProduct) => orderProduct.topLevel || orderProduct.standalone).map((orderProduct) =>
          <OrderItem key={orderProduct.id} orderProduct={orderProduct} order={order} />
        ) }
      </Box>

      <Box py={2} px={2}>
        <Box>
          <Text variant="h6">Order summary</Text>
        </Box>

        <Box display="flex" py={1}>
          <Text>Subtotal</Text>
          <Text ml="auto"><Currency value={order.finalSubtotalCents / 100} /></Text>
        </Box>

        {/* TODO */}
        {/* <Box display="flex" pb={1}>
          <Text>Discounts</Text>
          <Text ml="auto"><Currency value={0} /></Text>
        </Box>

        <Box display="flex" pb={1}>
          <Text>Sponsored</Text>
          <Text ml="auto"><Currency value={0} /></Text>
        </Box> */}

        <Box display="flex" pb={1}>
          <Text>Tax</Text>
          <Text ml="auto"><Currency value={order.finalTaxesCents / 100} /></Text>
        </Box>

        <Box display="flex" pt={1} sx={{ borderTop: '4px double grey'}}>
          <Text>Total</Text>
          <Text ml="auto"><Currency value={order.finalCents / 100} /></Text>
        </Box>
      </Box>
    </DialogContent>

    { order.userCancellable && <DialogActions>
      <Button
        fullWidth={false}
        variant="text"
        color="error"
        disabled={loading}
        onClick={() => setConfirmDialogOpen(true) }
      >Cancel order</Button>
    </DialogActions> }
  </Dialog>
}

export default OrderModal