import { gql } from 'graphql/schema/gql'

export const listedSchoolsQuery = gql(`
  query listedSchoolsQuery($state: String, $query: String, $limit: Int, $locationId: String) {
    listedSchools(state: $state, query: $query, limit: $limit, locationId: $locationId) {
      id
      name
      description
      city
      locationId
      createdAt
      updatedAt
      terms {
        id
        name
        rosters {
          id
          name
          description
          displayName
          group
          groupName
          sortOrder
          isVisible
          isArchived
        }
      }
    }
  }
`)

export default listedSchoolsQuery
