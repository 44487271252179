import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormikConfig, useField } from 'formik'
import { matchSorter } from 'match-sorter'
import {
  Alert,
  Autocomplete,
  Box,
  ConfirmDialog,
  Form,
  FormTextField,
  FormTelephoneField,
  FormSubmitButton,
  FormSelect,
  FormCheckbox,
  TextField,
  Button,
  Text,
} from 'components'
import { useStatesProvinces } from 'hooks/useStatesProvinces'
import { useArchiveAccountUser, useServicedSchools, useStripeAddPaymentModal } from '@market/hooks'
import { useAccountContext } from '@market/hooks/useCurrentAccount'
import { AccountUser, ClientError, ListedSchool, Roster, StateProvince } from '@market/graphql/schema/graphql'
import { AddPaymentMethodModal } from '@market/compositions/StripePayments'

export interface AccountUserFormProps {
  form: FormikConfig<object>
  accountUser?: AccountUser
  loading?: boolean
  submitted?: boolean
  errors?: ClientError[]
}

export type Role = {
  role: 'GUARDIAN' | 'STUDENT' | 'STAFF'
  name: string
  description: string
}

export const ROLES: Role[] = [
  {
    role: 'GUARDIAN',
    name: 'Parent',
    description: 'I need to order for my kids',
  },
  {
    role: 'STUDENT',
    name: 'Student',
    description: 'I need to order for myself',
  },
  {
    role: 'STAFF',
    name: 'Staff',
    description: 'I need to order for myself and/or my kids',
  },
]

type RosterSelectorProps = {
  rosterState: { state: StateProvince, location: ListedSchool, roster: Roster }
  statesLoading: boolean
  statesProvinces: StateProvince[]
  schoolsLoading: boolean
  servicedSchools: ListedSchool[]
  rosters: Roster[]
  setRosterState: React.Dispatch<React.SetStateAction<{
    state: StateProvince;
    location: ListedSchool;
    roster: Roster;
  }>>
}

const RosterSelector: React.FC<RosterSelectorProps> = ({ rosterState, statesLoading, statesProvinces, schoolsLoading, servicedSchools, rosters, setRosterState }) => {
  const [ {}, roleMeta ] = useField('role')
  const [ {}, {}, locationHelpers ] = useField('locationId')
  const [ {}, {}, rosterHelpers ] = useField('rosterId')

  if (roleMeta.value === 'GUARDIAN') return null

  return <Box>
    <Autocomplete
      selectOnFocus
      disabled={statesLoading}
      disableClearable={true}
      onChange={(_e, value: StateProvince) => {
        setRosterState({ ...rosterState, state: value, location: null, roster: null })
        locationHelpers.setValue(null)
        rosterHelpers.setValue('')
      }}
      value={rosterState.state}
      options={statesProvinces}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      getOptionLabel={(option: StateProvince) => option.name}
      renderInput={(params) => <TextField {...params} label="State" />}
    />

    <Autocomplete
      selectOnFocus
      disabled={schoolsLoading}
      autoComplete={true}
      disableClearable={true}
      onChange={(_e, value: ListedSchool) => {
        setRosterState({ ...rosterState, location: value })
        locationHelpers.setValue(value.locationId)
        rosterHelpers.setValue('')
      }}
      value={rosterState.location}
      options={servicedSchools}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      getOptionLabel={(option: ListedSchool) => option.name}
      renderInput={(params) => <TextField {...params} label="School Campus" placeholder="Find your school" />}
      filterOptions={(options, { inputValue }) => {
        // if (inputValue.length < 2) {
        //   if (!!rosterState.location) {
        //     return [rosterState.location]
        //   } else {
        //     return []
        //   }
        // }

        return matchSorter(options, inputValue, { keys: ['name'] })
      }}
      noOptionsText="No matching schools"
    />

    { !!rosterState.location && <FormSelect
      id="rosterId"
      name="rosterId"
      label={rosters[0]?.groupName}
      placeholder={rosters[0]?.groupName}
      onChange={(evt) => {
        setRosterState({ ...rosterState, roster: rosters?.find((r) => r.id === evt?.target?.value) || null })
        rosterHelpers.setValue(evt.target.value)
      }}
      value={rosterState.roster?.id}
      options={rosters.map((roster) => ({ value: roster.id, label: roster.displayName }))}
    /> }
  </Box>
}

const CellPhoneField: React.FC = () => {
  const [ {}, meta ] = useField('role')

  if (meta.value === 'STUDENT') return null

  return <Box>
    <FormTelephoneField
      id="phoneNumber"
      name="phoneNumber"
      label="Cell phone (optional)"
      placeholder="Cell phone"
    />
  </Box>
}

const SubmitButton: React.FC<{ loading: boolean }> = ({ loading }) => {
  // const [ {}, roleMeta ] = useField('role')
  // const [ {}, rosterMeta ] = useField('rosterId')

  return <FormSubmitButton loading={loading}>
    Save
  </FormSubmitButton>
}

export const AccountUserForm: React.FC<AccountUserFormProps> = ({ form, loading, submitted, errors, accountUser }) => {
  const { defaultState, defaultLocation, paymentMethods, setupIntent } = useAccountContext()

  const { data: { statesProvinces }, loading: statesLoading } = useStatesProvinces()
  const [ loadServicedSchools, { data: { servicedSchools }, loading: schoolsLoading } ] = useServicedSchools()
  const [ rosterState, setRosterState ] = useState<{ state: StateProvince, location: ListedSchool, roster: Roster }>({ state: null, location: null, roster: null })
  const [ confirmArchiveDialogOpen, setConfirmArchiveDialogOpen ] = useState(false)
  const [ archiveAccountUser, { loading: archiveLoading }] = useArchiveAccountUser()

  const stripeModal = useStripeAddPaymentModal(setupIntent?.clientSecret)

  const term = useMemo(() => {
    return rosterState.location?.terms[0]
  }, [rosterState.location])

  const rosters = useMemo(() => {
    return term?.rosters?.filter((roster) => roster.isVisible && !roster.isArchived)?.sort((a, b) => a.sortOrder - b.sortOrder) || []
  }, [term])

  const onClickConfirmArchive = useCallback(() => {
    archiveAccountUser({ variables: { id: accountUser.id } })
    setConfirmArchiveDialogOpen(false)
  }, [setConfirmArchiveDialogOpen, archiveAccountUser, accountUser])

  useEffect(() => {
    const accountState = statesProvinces?.find((state) => state.id === defaultState)
    const currentState = statesProvinces?.find((state) => state.id === (accountUser?.user?.roster?.term?.location?.address?.stateProvince || accountUser?.location?.address?.stateProvince))
    setRosterState((state) => ({ ...state, state: currentState || accountState || null }))
  }, [accountUser, defaultState, statesProvinces, setRosterState])

  useEffect(() => {
    const accountLocation = servicedSchools?.find((school) => school.locationId === defaultLocation)
    const currentLocation = servicedSchools?.find((school) => school.locationId === (accountUser?.user?.roster?.term?.location?.id || accountUser?.location?.id))
    setRosterState((state) => ({ ...state, location: ((currentLocation || accountLocation) as ListedSchool) || null }))
  }, [accountUser, defaultLocation, servicedSchools, setRosterState])

  useEffect(() => {
    const currentRoster = term?.rosters?.find((roster) => roster.id === accountUser?.user?.roster?.id)
    setRosterState((state) => ({ ...state, roster: (currentRoster as Roster) || null }))
  }, [accountUser, term?.rosters])

  useEffect(() => {
    loadServicedSchools({ variables: { state: rosterState.state?.id, limit: 500 } })
  }, [rosterState.state, loadServicedSchools])

  return <>
    <Form
      form={form}
      sx={{
        marginBottom: 0,
        '& .MuiButton-root:last-child': {
          marginBottom: '0',
        },
      }}
    >
      <Box>
        <FormSelect
          id="role"
          name="role"
          label="User Role"
          options={ROLES.map((role) => ({ label: role.name, value: role.role }))}
        />

        <FormTextField
          id="firstName"
          name="firstName"
          label="First Name"
        />

        <FormTextField
          id="lastName"
          name="lastName"
          label="Last Name"
        />

        <FormTextField
          id="preferredName"
          name="preferredName"
          label="Preferred Name (optional)"
        />

        <CellPhoneField />

        <RosterSelector
          statesLoading={statesLoading}
          schoolsLoading={schoolsLoading}
          statesProvinces={statesProvinces}
          servicedSchools={servicedSchools as ListedSchool[]}
          rosters={rosters}
          rosterState={rosterState}
          setRosterState={setRosterState}
        />

        { !!rosterState?.location?.location?.settings?.pos_enabled && <Box mb={2}>
          <Box>
            <FormCheckbox
              id="posEnabled"
              name="posEnabled"
              label="Enable point-of-sale"
              disabled={paymentMethods.length === 0}
            />
          </Box>

          { paymentMethods.length > 0 && <Text variant="caption">Point-of-sale allows family members to order items while in the cafeteria on the same day. Payment will be collected along with your other orders.</Text> }

          { paymentMethods.length === 0 && <Box>
            <Text variant="caption">Please add a valid payment method to your account before enabling point-of-sale for family members.</Text>
            <Box pt={2}><Button onClick={stripeModal.openModal} fullWidth={false} color="secondary">Add payment method</Button></Box>
          </Box> }
        </Box> }
      </Box>

      { !submitted && <>{ errors.map((error) => <Alert key={`${error.code}:${error.message}`} severity="error" sx={{ width: '100%', mb: 2 }}>
        {error.message}
      </Alert>)}</> }

      <SubmitButton loading={loading || archiveLoading} />
      { accountUser && <Button color="error" variant="text" loading={loading || archiveLoading} onClick={() => setConfirmArchiveDialogOpen(true)}>Archive user</Button> }
    </Form>
    <AddPaymentMethodModal {...stripeModal} />
    <ConfirmDialog
      open={confirmArchiveDialogOpen}
      onClose={() => setConfirmArchiveDialogOpen(false)}
      content={<Text>Are you sure you want to archive { accountUser?.user?.preferredName || accountUser?.user?.firstName }? You will no longer be able to manage or place orders for this family member.</Text>}
      action={<Button color="error" onClick={onClickConfirmArchive}>Archive { accountUser?.user?.preferredName || accountUser?.user?.firstName }</Button>}
    />

  </>
}

export default AccountUserForm
