import React from 'react'
import { Page, Button, Box } from 'components'
import { useStripeAddPaymentModal } from '@market/hooks'
import { useAccountContext } from '@market/hooks/useCurrentAccount'
import { AddPaymentMethodModal, PaymentMethodList } from '@market/compositions/StripePayments'

export const Settings: React.FC = () => {
  const { paymentMethods, setupIntent } = useAccountContext()
  const modal = useStripeAddPaymentModal(setupIntent?.clientSecret)
  const { openModal } = modal

  return <Page>
    <PaymentMethodList paymentMethods={paymentMethods} />

    <Box>
      <Button onClick={openModal} fullWidth={false} color="secondary">Add payment method</Button>
    </Box>

    <AddPaymentMethodModal {...modal} />
  </Page>
}

export default Settings
