import React, { useEffect } from 'react'
import { DateTime } from 'luxon'
import { useLocation } from 'react-router-dom'
import { Box, EmptyState, PageHeader, PageContent, Text } from 'components'
import { Spinner } from 'components/Loading'
import { Menu as MenuIcon } from 'icons'
import { useAddProductModal } from '@market/hooks'
import { AddProductModal, CartButton } from '@market/components'
import { MenuProduct, SchoolLocation } from '@market/graphql/schema/graphql'
import { ContextProvider, useFilteredAvailableMenuProducts, useContext } from '@market/hooks/useFilteredAvailableMenuProducts'
import { MenuDatePreview, MenuDate } from './MenuDate'
import { MenuFilters } from './MenuFilters'

type RouteProps = {
  groupedMenuProducts: { [x: string]: MenuProduct[] }
} & Pick<ReturnType<typeof useAddProductModal>, 'openModal' | 'closeModal'>

const MenuDateRoute: React.FC<RouteProps> = ({ groupedMenuProducts, openModal }) => {
  const { state } = useLocation()
  const { filters: { setAndApplyFilters } } = useContext()

  useEffect(() => {
    setAndApplyFilters({ filteredDate: !!state?.filteredDate ? DateTime.fromISO(state.filteredDate) : undefined })
  }, [ state, setAndApplyFilters ])

  if (!!state?.filteredDate) {
    if (groupedMenuProducts[state.filteredDate]?.length > 0) {
      return <MenuDate date={state.filteredDate} menuProducts={groupedMenuProducts[state.filteredDate]} openModal={openModal} />
    } else {
      return <EmptyState icon={MenuIcon} message="No items to display." />
    }
  } else {
    if (Object.values(groupedMenuProducts).flatMap(gmp => gmp).length === 0) {
      return <EmptyState icon={MenuIcon} message="No items to display." />
    } else {
      return <Box pb={6}>
        { Object.keys(groupedMenuProducts).map((groupDate) => {
          return <MenuDatePreview key={groupDate} date={groupDate} menuProducts={groupedMenuProducts[groupDate]} openModal={openModal} />
        }) }
        <Box
          display={{ xs: 'block', md: 'none' }}
          position="absolute"
          bottom={0}
          left={0}
          width="100%"
        >
          <Box px={2} pb={2}>
            <CartButton />
          </Box>
        </Box>
      </Box>
    }
  }
}

export const Menus: React.FC<{ locations: SchoolLocation[] }> = ({ locations }) => {
  // TODO: Move this (and the component) further down into the nested components that use it
  const { openModal, closeModal, setDate: modalSetDate, open: modalOpen, menuProduct: modalMenuProduct, date: modalDate } = useAddProductModal()
  const menuProductFilters = useFilteredAvailableMenuProducts(locations)
  const { loading, data: { filteredMenuProducts, groupedMenuProducts } } = menuProductFilters

  return <ContextProvider { ...menuProductFilters }>
    <AddProductModal open={modalOpen} date={modalDate} menuProduct={modalMenuProduct} setDate={modalSetDate} onClose={closeModal} />

    <PageHeader sx={(theme) => ({
      [theme.breakpoints.up('md')]: {
        paddingBottom: 0
      }
    })}>
      <Box display={{ xs: 'none', md: 'block' }}>
        <Text variant="h5">Menus</Text>
      </Box>

      <Box mt={1}>
        <MenuFilters />
      </Box>
    </PageHeader>

    <PageContent>
      { loading && filteredMenuProducts.length === 0 && <Spinner message="Loading Menus..." /> }
      { (!loading || filteredMenuProducts.length > 0) && <MenuDateRoute groupedMenuProducts={groupedMenuProducts} openModal={openModal} closeModal={closeModal} /> }
    </PageContent>
  </ContextProvider>
}

export default Menus
