import React, { /* useEffect */ } from 'react'
import { Alert, Form, FormTextField, FormSubmitButton } from 'components'
import { useLoginForm } from 'hooks'
// import store from 'store2'

export const LoginForm = () => {
  const [form, { loading, errors }] = useLoginForm()

  // useEffect(() => {
  //   store.clearAll()
  // }, [])

  return (
    <Form form={form}>
      <FormTextField
        id="email"
        name="email"
        label="Email address"
        autoFocus={true}
        autoComplete="username"
      />

      <FormTextField
        id="password"
        name="password"
        label="Password"
        type="password"
      />

      { !loading && <>{ errors.map((error) => <Alert key={`${error.code}:${error.message}`} severity="error" sx={{ width: '100%', mb: 2 }}>
        {error.message}
      </Alert>)}</> }

      <FormSubmitButton
        loading={loading}
      >
        Sign in
      </FormSubmitButton>
    </Form>
  )
}

export default LoginForm
