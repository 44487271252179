import React, { useCallback, useMemo } from 'react'
import { Text } from 'components'
import { HelpDialog as RequestHelpDialog, HelpOption } from 'compositions/HelpDialog'
import { useSubmitHelpRequest } from '@market/hooks'
import type { HelpDialogActions, HelpDialogState } from 'hooks/useHelpDialog'

const helpDialogOptions: HelpOption[] = [
  { type: 'CANCEL_ORDER', name: 'Cancel an upcoming order', notesRequired: true },
  { type: 'MISSING_ITEMS', name: 'My order was missing items', notesRequired: true },
  { type: 'QUALITY_ISSUE', name: 'My order had a quality issue', notesRequired: true },
  { type: 'WRONG_ITEMS', name: 'Received wrong items', notesRequired: true },
  { type: 'MENU_UPDATE', name: 'Request a menu update', notesRequired: true },
  { type: 'BILLING_ISSUE', name: 'Get help with billing', notesRequired: true },
  { type: 'OTHER_ISSUE', name: 'I need something else', notesRequired: true },
]

const HelpDialogSuccess = () => <>
  <Text pb={2}>What to expect:</Text>
  <Text pb={2}>The Ordo Support Team will contact you as soon as possible with a resolution. In the case of an emergency, our lines are <a href="mailto:support@ordoschools.com">support@ordoschools.com</a> or <a href="tel:8557562909">855 756 2909</a>.</Text>
  <Text>Please wait 1-2 business days for a response.</Text>
</>

const HelpDialogError = () => <>
  <Text pb={2}>There was a problem submitting your request to the Ordo Support Team.</Text>
  <Text pb={2} fontWeight="bold">Please reload the page and try again.</Text>
  <Text pb={2}>If you continue to experience problems submitting your request, you may contact us directly. Our lines are <a href="mailto:support@ordoschools.com">support@ordoschools.com</a> or <a href="tel:8557562909">855 756 2909</a>.</Text>
</>

export const HelpDialog: React.FC<HelpDialogState & HelpDialogActions> = ({
  open,
  selected,
  notes,
  errors,
  requestId,
  setHelpDialog,
  closeHelpDialog,
  clearHelpDialog,
}) => {

  const selectedOption = useMemo(() => helpDialogOptions.find((option) => option.type === selected), [ selected ])

  const [ submitHelpRequest, { loading } ] = useSubmitHelpRequest({ request: { type: selectedOption?.type, subject: selectedOption?.name, notes: notes } })

  const onChange = useCallback((state: Partial<HelpDialogState>) => setHelpDialog(state), [ setHelpDialog ])

  const submitHelpDialog = useCallback(() => {
    submitHelpRequest().then(({ data }) => {
      setHelpDialog({
        errors: data.submitHelpRequest.errors,
        requestId: data.submitHelpRequest.requestId,
      })
    })
  }, [ submitHelpRequest, setHelpDialog ])

  return <RequestHelpDialog
    open={open}
    notes={notes}
    requestId={requestId}
    errors={errors}
    selected={selectedOption}
    options={helpDialogOptions}
    loading={loading}
    SuccessMessage={HelpDialogSuccess}
    ErrorMessage={HelpDialogError}
    onClose={() => !!requestId ? clearHelpDialog() : closeHelpDialog()}
    onChange={onChange}
    onSubmit={submitHelpDialog}
  />
}

export default HelpDialog
