import type { QueryListedSchoolsArgs, ListedSchoolsQueryQuery, ListedSchoolsQueryQueryVariables } from 'graphql/schema/graphql'
import { listedSchoolsQuery } from 'graphql/queries'
import { LazyQueryResultTuple, useLazyQuery } from './useLazyQuery'
import { publicClient } from 'graphql/client'

type ListedSchoolsArgs = QueryListedSchoolsArgs & {
  pollInterval?: number
}

export const useListedSchools = (args?: ListedSchoolsArgs): LazyQueryResultTuple<ListedSchoolsQueryQuery, ListedSchoolsQueryQueryVariables> => {
  const [ listedSchools, { data, ...rest } ] = useLazyQuery<ListedSchoolsQueryQuery, ListedSchoolsQueryQueryVariables>(
    listedSchoolsQuery,
    {
      variables: { state: args?.state, limit: args?.limit },
      pollInterval: args?.pollInterval,
      client: publicClient,
      // notifyOnNetworkStatusChange: true
    }
  )

  return [
    listedSchools,
    {
      ...rest,
      data: {
        listedSchools: [],
        ...data,
      },
      previousData: {
        listedSchools: [],
        ...rest?.previousData,
      },
    },
  ]
}

export default useListedSchools
