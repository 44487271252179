import React from 'react'
import { Page, Text } from 'components'
import { UseSubscriptionWizard } from '@market/hooks/useSubscriptionWizard'
import { CreateSubscriptionForm } from '../CreateSubscriptionForm'

export const PaymentMethodStep: React.FC<{ wizard: UseSubscriptionWizard }> = ({ wizard }) => {

  return <Page unpadded noMargins>
    <Text variant="h4" mb={2}>{ wizard.activeStep.name }</Text>

    <Text mb={2}>Select the payment method you&apos;d like to use for this subscription. You will not be charged until your first subscription order is placed.</Text>

    <CreateSubscriptionForm
      accountUser={wizard.accountUser}
      onSuccess={wizard.navigateToNext}
    />
  </Page>
}

export default PaymentMethodStep
