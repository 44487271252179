import lightRed from './lightRed'
import darkRed from './darkRed'

export const red = {
  50: '#ffebee',
  100: '#ffcdd2',
  200: '#ef9a9a',
  300: '#e57373',
  400: '#ef5350',
  500: '#f44336',
  600: '#e53935',
  700: '#d32f2f', // primary
  800: '#c62828',
  900: '#b71c1c',
  A100: '#ffcdd2',
  A200: '#ef9a9a',
  A400: '#ef5350',
  A700: '#d32f2f',
}

export const palette = {
  main: red[700],
  // light: lightRed[400],
  // dark: darkRed[800],
  // contrastText: '#ffffff',
}

export { lightRed, darkRed }

export default red
