import React, { useMemo } from 'react'
import { Button, ExtendedButtonProps } from 'components/Button'
import { Cart as CartIcon } from 'icons'
import useDrawerSettings from 'hooks/useDrawerSettings'
import { useAccountContext } from '@market/hooks/useCurrentAccount'

export const CartButton: React.FC<ExtendedButtonProps> = ({ onClick, sx=[], ...props }) => {
  const [{}, { setContentDrawer }] = useDrawerSettings()
  const { carts } = useAccountContext()
  const cartsQuantity = useMemo(() => carts.reduce((memo, cart) => {
    return memo + cart.quantity
  }, 0), [carts])
 
  if (cartsQuantity === 0) return null

  return <Button
    {...props}
    onClick={(evt) => {
      if (!!onClick) onClick(evt)
      setContentDrawer(true, 'carts')
    }}
    sx={[{ py: 2 }, ...(Array.isArray(sx) ? sx : [sx])]}
  >
    <CartIcon sx={{ mr: 1 }} /> Cart &#x2022; { cartsQuantity }
  </Button>
}

export default CartButton
