import React, { useCallback, useMemo, useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
import { Pagination, styled } from '@mui/material'
import useViewport from 'hooks/useViewport'
import { Box, IconButton } from 'components'
import { Back, Next } from 'icons'

export type CarouselProps = React.PropsWithChildren<{
  index: number
  // onChange: (index: number) => void
}>

// const ArrowButton = styled(IconButton)({
//   position: 'absolute',
//   top: 0,
//   marginTop: '10px'
// })

// const BackButton = styled(ArrowButton)({
//   left: 0,
// })

// const NextButton = styled(ArrowButton)({
//   right: 0,
// })

const StyledPagination = styled(Pagination)({
  display: 'flex',
  justifyContent: 'center',
  '& .MuiPaginationItem-outlined': {
    fontSize: 0,
    minWidth: '14px',
    width: '14px',
    height: '14px',
    padding: 0,
  }
})

export const Carousel: React.FC<CarouselProps> = ({ children, index }) => {
    const { isMedium } = useViewport()
  const count = useMemo(() => React.Children.count(children), [children])
  const [ activeIndex, setActiveIndex ] = useState(index)

  const onClickBack = useCallback(() => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1)
    } else {
      setActiveIndex(count - 1)
    }
  }, [count, activeIndex, setActiveIndex])

  const onClickNext = useCallback(() => {
    if (activeIndex < (count - 1)) {
      setActiveIndex(activeIndex + 1)
    } else {
      setActiveIndex(0)
    }
  }, [count, activeIndex, setActiveIndex])

  return <Box display="flex" flexDirection="column">
    <Box position="relative">
      <Box sx={(theme) => ({
        zIndex: theme.zIndex.modal,
      })}>
        <SwipeableViews index={activeIndex} onChangeIndex={(index) => setActiveIndex(index)}>
          { children }
        </SwipeableViews>
      </Box>

      { isMedium && <Box sx={(theme) => ({
        display: 'flex',
        justifyContent: 'space-between',
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
        zIndex: theme.zIndex.snackbar,
        '& .MuiIconButton-root': {
          mx: 2,
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 1)',
          },
        },
      })}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <IconButton size="small" onClick={onClickBack}>
            <Back fontSize="large" />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <IconButton size="small" onClick={onClickNext}>
            <Next fontSize="large"/>
          </IconButton>
        </Box>
      </Box> }
    </Box>
    <StyledPagination
      count={count}
      hideNextButton
      hidePrevButton
      size="small"
      variant="outlined"
      page={activeIndex + 1}
      onChange={(_evt, index) => setActiveIndex(index - 1)}
    />
  </Box>
}

export default Carousel
