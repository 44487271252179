import React from 'react'
import { Alert, Box, Form, FormCheckbox, FormFieldError, FormSubmitButton, Text } from 'components'
import { OnSuccessFn } from 'hooks/useMutationForm'
import { AccountUser, UpdateSubscriptionPayload } from '@market/graphql/schema/graphql'
import { useEditSubscriptionForm, UpdateSubscriptionFormInput } from '@market/hooks/useEditSubscriptionForm'

export const EditSubscriptionForm: React.FC<{ accountUser: AccountUser, onSuccess?: OnSuccessFn<UpdateSubscriptionPayload, UpdateSubscriptionFormInput> }> = ({ accountUser, onSuccess }) => {
  const [ mutationForm, { errors, loading, submitted } ] = useEditSubscriptionForm(accountUser, { onSuccess })

  return <Form form={mutationForm}>
    <Box mb={2}>
      <Text variant="h6" mb={2}>Dietary needs</Text>
      <Text mb={2}>If you have any dietary requirements, please select the appropriate options below and Ordo won&apos;t add any items that don&apos;t match your preferences.</Text>

      { accountUser.subscription.availableSubscriptionTags.map((tag) => {
        return <FormCheckbox
          key={tag.id}
          id={`subscription.subscriptionTagIds`}
          name={`subscription.subscriptionTagIds`}
          label={tag.name}
          value={tag.id}
        />
      }) }
      <FormFieldError
        id="subscription.subscriptionTagIds"
        name="subscription.subscriptionTagIds"
      />


      <Text variant="h6" my={2}>Days of the week</Text>

      <Text mb={2}>Select at least two days a week to subscribe.</Text>

      { Object.keys(accountUser.subscription.dailyOptions).map((day) => {
        const dailyOption = accountUser.subscription.dailyOptions[day]
        if (!dailyOption.active) return null

        return <FormCheckbox
          key={day}
          id={`subscription.dailyOptions.${day}.enabled`}
          name={`subscription.dailyOptions.${day}.enabled`}
          label={`${day[0].toUpperCase()}${day.slice(1)}`}
        />
      }) }

      <FormFieldError
        id="subscription.dailyOptions"
        name="subscription.dailyOptions"
      />
    </Box>

    { !submitted && <>{ errors.map((error) => <Alert key={`${error.code}:${error.message}`} severity="error" sx={{ width: '100%', mb: 2 }}>
      {error.message}
    </Alert>)}</> }

    <FormSubmitButton loading={loading}>
      Save
    </FormSubmitButton>
  </Form>

}

export default EditSubscriptionForm
