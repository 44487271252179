import React, { useCallback } from 'react'
import {
  Box,
  Button,
  FormGroup,
  Text,
} from 'components'
import { ExtendedButtonProps } from 'components/Button'
import { SetFilters, Filters, FilterOnChange } from 'hooks/useFilters'

export interface ButtonFilterProps<T extends object, D extends object, K extends keyof T = keyof T> {
  filterName: string
  filterDefinitions: Filters<T, D, K>
  filters: T
  multiple?: boolean
  required?: boolean
  fullWidth?: boolean
  row?: boolean
  hideLabel?: boolean
  sx?: ExtendedButtonProps['sx']
  selectedVariant?: ExtendedButtonProps['variant']
  deselectedVariant?: ExtendedButtonProps['variant']
  setFilters: SetFilters<T>
}

export function ButtonFilter<T extends object, D extends object, K extends keyof T = keyof T>({ children, ...props }: React.PropsWithChildren<ButtonFilterProps<T, D, K>>) {
  const {
    filterName,
    filterDefinitions,
    filters,
    row,
    fullWidth,
    multiple,
    required=true,
    hideLabel,
    sx,
    selectedVariant='contained',
    deselectedVariant='outlined',
    setFilters
  } = props
  const filterDefinition = filterDefinitions.find((definition) => definition.name === filterName)

  const onChange: FilterOnChange<object> = useCallback((value, filters, selected) => {
    const withoutOption = !!multiple ? filters[filterName].filter((val: string) => val !== value) : []

    if (selected) {
      return { [filterName]: [ ...withoutOption, value ] }
    } else if (!required || withoutOption.length > 0) {
      return { [filterName]: withoutOption }
    }
  }, [filterName, multiple, required])

  return <Box width="100%">
    { !hideLabel && <Text variant="subtitle1" fontWeight="medium" pb={2}>{ filterDefinition.label }</Text> }

    { (!filterDefinition.options || filterDefinition.options?.length === 0) && <Text variant="caption">No filter options found in your current current search. Try expanding your other filters.</Text> }
    <FormGroup row={row} sx={sx}>
      { React.Children.count(children) === 0 && filterDefinition.options?.map((option) => {
        const selected = filters[filterName].includes(option.value)

        return <Button
          key={option.value}
          fullWidth={!!fullWidth}
          disabled={!!option.disabled}
          variant={selected ? selectedVariant : deselectedVariant}
          onClick={() => setFilters((filterDefinition.onChange || onChange)(option.value, filters, !selected))}
          sx={row ? { mr: 2 } : { mb: 1 }}
        >{`${option.key}${option.count >= 0 ? ` (${option.count})` : ''}`}</Button>
      }) }

      { React.Children.count(children) > 0 && React.Children.map(children, (child) => {
        const childProps = (child as React.JSX.Element).props
        const option = filterDefinition.options.find((opt) => opt.value === childProps.value)
        const selected = filters[filterName].includes(option.value)

        return React.cloneElement(child as React.JSX.Element, {
          fullWidth: !!fullWidth,
          ...childProps,
          disabled: !!option.disabled,
          onClick: () => setFilters((filterDefinition.onChange || onChange)(option.value, filters, !selected)),
          variant: selected ? selectedVariant : deselectedVariant,
        })
      }) }
    </FormGroup>
  </Box>
}

export default ButtonFilter
