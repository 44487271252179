import React, { createContext, useContext, useMemo } from 'react'
import { useGlobalContext, useMe } from 'hooks'
import useAccount from './useAccount'
import useAccountPaymentMethods from './useAccountPaymentMethods'
import useAccountCarts from './useAccountCarts'

export type UseCurrentAccount = ReturnType<typeof useCurrentAccount>

export const Context = createContext<UseCurrentAccount>({} as UseCurrentAccount)

export const ContextProvider = ({ children, ...props }: React.PropsWithChildren<UseCurrentAccount>) => {
  return <Context.Provider value={props as UseCurrentAccount}>
    {children}
  </Context.Provider>
}

export const useAccountContext = () => useContext(Context)

export const useCurrentAccount = () => {
  const me = useMe()
  const { data: accountData, loading: accountLoading, called: accountCalled } = useAccount({ accountId: me?.me?.account?.id })
  const { data: paymentMethodData } = useAccountPaymentMethods({ accountId: me?.me?.account?.id })
  const { data: cartsData } = useAccountCarts({ accountId: me?.me?.account?.id })
  const { selectedState, selectedSchool } = useGlobalContext()

  const account = useMemo(() => {
    return accountData?.account
  }, [accountData])

  const accountUser = useMemo(() => {
    return account?.accountUsers?.find((acctUser) => acctUser.user.id === me?.me?.id)
  }, [account?.accountUsers, me])

  const accountUsers = useMemo(() => {
    return account?.accountUsers?.filter((acctUser) => !acctUser.isArchived) || []
  }, [account?.accountUsers])

  const locations = useMemo(
    () =>
      accountUsers?.map((acctUser) => acctUser.user.roster?.term?.location || acctUser.location )
      ?.filter((v, i, a) => v !== undefined && v !== null && a.indexOf(v) === i)
      ?.sort((a, b) => a.displayName.localeCompare(b.displayName)) || [],
    [accountUsers]
  )

  const needsOnboarding = useMemo(() => {
    if (!accountCalled || !!accountLoading) return false
    if (!me?.me || !account || !me.me.id || !account.id) return false
    return locations.length === 0
  }, [me, account, accountCalled, accountLoading, locations])

  const subscriptionUsers = useMemo(() => {
    return accountUsers?.filter((acctUser) => {
      return ((acctUser.user.roster?.term?.location || acctUser.location)?.settings?.opt_in_programs as string[])?.includes('SUBSCRIPTIONS')
    })
  }, [accountUsers])

  const subscriptionsEnabled = useMemo(() => {
    return subscriptionUsers?.length > 0
  }, [subscriptionUsers])

  const posEnabled = useMemo(() => {
    return locations.some((location) => !!location.settings?.pos_enabled)
  }, [locations])

  const subscriptions = useMemo(() => account.accountUsers?.map((acctUsr) => acctUsr.subscription), [account.accountUsers])?.filter((sub) => sub)

  return {
    me,
    account,
    accountUser,
    accountUsers,
    locations,
    needsOnboarding,
    paymentMethods: paymentMethodData?.accountPaymentMethods?.paymentMethods?.filter((paymentMethod) => !paymentMethod.isArchived),
    setupIntent: paymentMethodData?.accountPaymentMethods?.setupIntent,
    carts: cartsData?.accountCarts,
    subscriptions,
    subscriptionUsers: subscriptionUsers,
    posEnabled,
    subscriptionsEnabled,
    defaultState: locations[0]?.address?.stateProvince || selectedState?.id,
    defaultLocation: locations[0]?.id || selectedSchool?.locationId,
  }
}

export default useCurrentAccount