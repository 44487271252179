export const lightYellow = {
  50: '#fffbe2',
  100: '#fff3b6', // primary
  200: '#ffeb87',
  300: '#ffe557',
  400: '#ffdc32',
  500: '#ffd519',
  600: '#ffc70d',
  700: '#ffb301',
  800: '#ffa300',
  900: '#ff8400',
  A100: '#fff3b6',
  A200: '#ffeb87',
  A400: '#ffdc32',
  A700: '#ffb301',
}

export default lightYellow
