import React, { useMemo } from 'react'
import { Box, Page, Button, Text } from 'components'
import { UserCart } from '@market/compositions/Carts'
import { UseSubscriptionWizard } from '@market/hooks/useSubscriptionWizard'
import { useAccountCarts, useActivateSubscription } from '@market/hooks'
import { useAccountContext } from '@market/hooks/useCurrentAccount'

export const ActivateStep: React.FC<{ wizard: UseSubscriptionWizard }> = ({ wizard }) => {
  const [activateSubscription, { loading: activateLoading, called: activateCalled }] = useActivateSubscription()
  const { account } = useAccountContext()
  const { data: { accountCarts }, loading: cartsLoading } = useAccountCarts({ accountId: account?.id })
  const loading = useMemo(() => activateCalled && (activateLoading || cartsLoading), [activateCalled, activateLoading, cartsLoading])
  const cart = useMemo(() => {
    return accountCarts.find((cart) => cart.id === wizard.accountUser.user.id)
  }, [accountCarts, wizard])
  
  return <Page unpadded noMargins>
    <Box width="100%">
      <Text variant="h4" mb={2}>{ wizard.activeStep.name }</Text>



      { wizard.accountUser.subscription.status !== 'enabled' && <Box>
        <Text mb={4}>Once you activate your subscription, we&apos;ll fill your cart for the upcoming week with items based on your selections. If you haven&apos;t checked out by the end of this week we&apos;ll check you out automatically on Friday.</Text>
        <Button disabled={loading} loading={loading} onClick={() => activateSubscription({ variables: { subscriptionId: wizard.accountUser.subscription.id } })}>Activate subscription</Button>
      </Box> }

      { wizard.accountUser.subscription.status === 'enabled' && cart && <Box>
        <Text mb={2} fontWeight="bold">Your subscription has been activated!</Text>  
        <Text mb={4}>Your cart has been filled with meals based on your selections and preferences. You can always edit your cart before Friday if you want to make changes.</Text>
        <UserCart cart={cart} />
      </Box> }
    </Box>
  </Page>
}

export default ActivateStep
