import React, { PropsWithChildren, ReactElement } from 'react'
import styled from '@mui/material/styles/styled'
import {
  Formik,
  Form as FormikForm,
  FormikConfig,
  FormikValues,
} from 'formik'
import { SxProps } from '@mui/material'

interface FormProps<T extends FormikValues = object> {
  children: ReactElement | ReactElement[]
  form: FormikConfig<T>
  sx?: SxProps
}

const FormStyled = styled(FormikForm)({
  width: '100%',
  marginTop: '1rem',
  marginBottom: '1rem',
  '& .MuiFormControl-root': {
    marginBottom: '1rem',
  },
  '& .MuiButton-root': {
    marginBottom: '1rem',
  },
})

export const Form: React.FC<PropsWithChildren<FormProps>> = ({ children, sx, form: { validateOnBlur=false, validateOnChange=false, ...form } }) => {
  return (
    <Formik {...form} validateOnBlur={validateOnBlur} validateOnChange={validateOnChange}>
      <FormStyled sx={sx}>
        {children}
      </FormStyled>
    </Formik>
  )
}

export default Form
