export const lightGreen = {
  50: '#f1fcef',
  100: '#ddf6d6', // primary
  200: '#c7f0bb',
  300: '#afea9f',
  400: '#9be488',
  500: '#89de73',
  600: '#7acd69',
  700: '#67b85e',
  800: '#55a353',
  900: '#347f40',
  A100: '#ddf6d6',
  A200: '#c7f0bb',
  A400: '#9be488',
  A700: '#67b85e',
}

export default lightGreen
