import React from 'react'
import { Image } from 'mui-image'
import Markdown from 'react-markdown'
import { styled } from '@mui/material'
import {
  Box,
  Chip,
  Button,
  IconButton,
  Text,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Currency,
} from 'components'
import { DialogProps } from 'components/Dialog/Dialog'
import {
  ArrowBack,
  Close as CloseIcon,
} from 'icons'
import { useViewport } from 'hooks'
import { Product } from '@market/graphql/schema/graphql'

import { Carousel } from 'components/Carousel'

const StyledMarkdown = styled(Markdown)({
  whiteSpace: 'normal',
  'p': { padding: 0, margin: 0 }
})

export const ProductModal: React.FC<React.PropsWithChildren<DialogProps & {
  product: Product,
  onClose: () => void,
  actions?: React.ReactNode,
}>> = ({ children, product, open, actions, onClose }) => {
  const { isMedium } = useViewport()

  return <Dialog open={!!open} fullScreen={!isMedium} onClose={onClose} maxWidth="sm" fullWidth>
    <DialogTitle display="flex" flexWrap="nowrap" gap={2} sx={{ alignItems: { md: 'center' } }}>
      { !isMedium && <Box>
        <Button variant="text" onClick={onClose}><ArrowBack /></Button>
      </Box> }

      <Box>
        <Text variant="h6">{ product.name }</Text>
      </Box>

      { isMedium && <Box ml="auto">
        <IconButton onClick={onClose}><CloseIcon /></IconButton>
      </Box> }
    </DialogTitle>

    <DialogContent sx={{ padding: 0 }}>
      <Box position="relative" pb={2}>
        { product.images.length > 0 && <Carousel index={0}>
          { product.images.map((image) => <Box key={image.id}>
            <Box position="relative">
              <Image
                src={image.uri}
                duration={0}
                width="100%"
                height="240px"
              />
            </Box>
          </Box>) }
        </Carousel> }

        { product.images.length === 0 && <>
          <Image
            src={product.featuredImage}
            duration={0}
            width="100%"
            height="240px"
          />
        </> }

        <Box sx={(theme) => ({ position: 'absolute', bottom: '24px', right: 0, px: 2, py: 1, backgroundColor: theme.palette.background.default, borderTopLeftRadius: '10px' })}>
          <Text><Currency value={product.price / 100} /></Text>
        </Box>
      </Box>

      <Box px={2}>
        <StyledMarkdown>{ product.description }</StyledMarkdown>

        <Box display="flex" flexWrap="wrap" gap={1} mt={2}>
          { product.tags.filter((tag) => tag.type === 'dietary').map((tag) => <Chip key={tag.id} label={tag.name} size="small" sx={{ backgroundColor: tag.color }} /> )}
        </Box>
      </Box>

      <Box py={4} px={2}>
        { children }
      </Box>
    </DialogContent>

    { !!actions && <DialogActions>
      { actions }
    </DialogActions> }
  </Dialog>
}

export default ProductModal
