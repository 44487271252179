import React, { useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Text,
  Dialog,
  DialogTitle,
  DialogContent,
} from 'components'
import { ArrowBack } from 'icons'
import { ArrowForwardIos } from '@mui/icons-material'
import { MenuProduct } from '@market/graphql/schema/graphql'
import { useContext } from '@market/hooks/useFilteredAvailableMenuProducts'
import { MenuSection } from './MenuSection'
import { MenuProductCard, CartButton } from '@market/components'
import { useViewport } from 'hooks'
import { DateTime } from 'luxon'
import { OtherFilters } from './MenuFilters'

const productTypeSections = {
  "Meals": [
    "Catalog::Bundle",
    "Catalog::MainDish",
  ],
  "Sides & Extras": [
    "Catalog::SideDish",
    "Catalog::Condiment",
    "Catalog::AddOn",
    "Catalog::Dessert",
  ],
  "Drinks": [
    "Catalog::Beverage",
  ],
}

type GroupedMenuProducts = {
  ["Meals"]?: MenuProduct[]
  ["Sides & Extras"]?: MenuProduct[]
  ["Drinks"]?: MenuProduct[]
}

type MenuDateProps = {
  date: string,
  menuProducts: MenuProduct[],
  openModal: (menuProduct: MenuProduct, date: string) => void
}

export const MenuDatePreview: React.FC<Omit<MenuDateProps, 'filters'>> = ({ date, menuProducts, openModal }) => {
  const { isLarge, isExtraLarge, isXXLarge } = useViewport()
  const { filters: { setAndApplyFilters } } = useContext()

  const groupedMenuProducts: GroupedMenuProducts = useMemo(() => {
    return menuProducts.reduce((memo, menuProduct) => {
      const productSection = Object.keys(productTypeSections).find(
        (section) => productTypeSections[section].includes(menuProduct.product.catalogProduct.type)
      )

      memo[productSection] ||= []
      memo[productSection].push(menuProduct)

      return memo
    }, Object.keys(productTypeSections).reduce((memo, key) => {
      memo[key] ||= []
      return memo
    }, {}))
  }, [menuProducts])

  if (Object.values(groupedMenuProducts).flatMap((sect) => sect).length === 0) return null

  return <Box mb={4}>
    <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
      <Box>
        <Text variant="h5">{ DateTime.fromISO(date).toFormat('ccc, LLL d') }</Text>
      </Box>

      <Box>
        <Button
          component={Link}
          to={`/menus`}
          state={{ filteredDate: date }}
          replace={false}
          color="inherit"
          variant="text"
          onClick={() => setAndApplyFilters({ filteredDate: DateTime.fromISO(date) })}
          sx={{ textDecoration: 'underline', '&:focus': { textDecoration: 'underline' }, '&:hover': { textDecoration: 'underline' } }}
        >
          View more
        </Button>
      </Box>
    </Box>

    <Box display="flex" flexWrap={{ xs: "nowrap", xl: "nowrap" }} overflow="auto">
      { Object.values(groupedMenuProducts).flat().slice(0, (isXXLarge ? 8 : (isExtraLarge ? 8 : (isLarge ? 6 : 4)))).map((menuProduct) =>
        <Box key={menuProduct.id} sx={(theme) => ({ p: 1, width: theme.spacing(theme.sizes.cardWidth), minWidth: theme.spacing(theme.sizes.cardWidth) })}>
          <MenuProductCard date={date} menuProduct={menuProduct} openModal={openModal} />
        </Box>
      ) }

      <Box sx={(theme) => ({ p: 1, width: theme.spacing(theme.sizes.cardWidth), minWidth: theme.spacing(theme.sizes.cardWidth) })}>
        <Box
          display="flex"
          flexDirection="column"
          sx={{ cursor: 'pointer', width: '100%', minWidth: '100%', textDecoration: 'none' }}
          component={Link}
          to={`/menus`}
          state={{ filteredDate: date }}
          replace={false}
          onClick={() => setAndApplyFilters({ filteredDate: DateTime.fromISO(date) })}
        >
          <Box position="relative" sx={{ width: '100%', height: 'auto', aspectRatio: '1/1' }}>
            <Box sx={(theme) => ({
                width: '100%',
                height: 'auto',
                aspectRatio: '1/1',
                overflow: 'hidden',
                borderRadius: '10px',
                '&:hover': {
                  backgroundColor: theme.palette.grey[200],
                },
              })}
            >
              <Box width="100%" height="100%" display="flex" flexDirection="column" justifyContent="stretch" alignItems="stretch">
                <Box margin="auto" textAlign="center">
                  <Box display="flex" alignItems="center">
                    <Text variant="h5" sx={(theme) => ({ color: theme.palette.primary.main, lineHeight: '24px' })} mr={1}>View more</Text>
                    <ArrowForwardIos color="primary" />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  </Box>
}

export const MenuDate: React.FC<MenuDateProps> = ({ date, menuProducts, openModal }) => {
  const navigateTo = useNavigate()
  const { isMedium } = useViewport()

  const groupedMenuProducts: GroupedMenuProducts = useMemo(() => {
    return menuProducts.reduce((memo, menuProduct) => {
      const productSection = Object.keys(productTypeSections).find(
        (section) => productTypeSections[section].includes(menuProduct.product.catalogProduct.type)
      )

      memo[productSection] ||= []
      memo[productSection].push(menuProduct)

      return memo
    }, Object.keys(productTypeSections).reduce((memo, key) => {
      memo[key] ||= []
      return memo
    }, {}))
  }, [menuProducts])

  if (Object.values(groupedMenuProducts).flatMap((sect) => sect).length === 0) return null

  if (isMedium) {
    return <Box>
      <Box display="flex" flexDirection="column" mb={4}>
        { Object.keys(groupedMenuProducts).map((section) =>
          <MenuSection key={section} date={date} section={section} menuProducts={groupedMenuProducts[section]} openModal={openModal} />
        ) }
      </Box>
    </Box>
  } else {
    return <Dialog open fullScreen>
      <DialogTitle>
        <Box display="flex" flexWrap="nowrap" alignItems="center" justifyContent="space-between" gap={2}>
          <Box>
            <Button variant="text" fullWidth={false} onClick={() => navigateTo(-1)}><ArrowBack /></Button>
          </Box>

          <Box>
            <Text variant="h5">{ DateTime.fromISO(date).toFormat('ccc, LLL d') }</Text>
          </Box>

          <Box>
            <OtherFilters />
          </Box>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ px: { xs: 0, lg: 2 } }}>
        <Box display="flex" flexDirection="column" pb={6}>
          { Object.keys(groupedMenuProducts).map((section) =>
            <MenuSection key={section} date={date} section={section} menuProducts={groupedMenuProducts[section]} openModal={openModal} />
          ) }
        </Box>
        <Box
          display={{ xs: 'block', md: 'none' }}
          position="absolute"
          bottom={0}
          left={0}
          width="100%"
        >
          <Box px={2} pb={2}>
            <CartButton />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  }
}

export default MenuDate
