import { submitHelpRequestMutation } from '@market/graphql/mutations'
import { useMarketMutation } from './useMarketMutation'
import type { ClientError, SupportRequestInput } from '@market/graphql/schema/graphql'

type SubmitHelpRequestMutationArgs = {
  request: SupportRequestInput
}

export const useSubmitHelpRequest = ({ request }: SubmitHelpRequestMutationArgs) => {
  const result = useMarketMutation<{ submitHelpRequest: { requestId: string, errors: ClientError[] } }, SubmitHelpRequestMutationArgs>(
    submitHelpRequestMutation,
    {
      variables: { request },
      // notifyOnNetworkStatusChange: true
    }
  )

  return result
}

export default useSubmitHelpRequest
