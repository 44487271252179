import React, { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Page, Text, Button, ConfirmDialog, Box } from 'components'
import { useAccountContext } from '@market/hooks/useCurrentAccount'
import { useCancelSubscription, useActivateSubscription } from '@market/hooks'
import { EditSubscriptionForm } from '@market/compositions/Subscriptions'
import { PauseSubscriptionDialog } from '@market/compositions/Subscriptions/PauseSubscriptionDialog'
import { DateTime } from 'luxon'

export const EditSubscription: React.FC = () => {
  const { accountUserId } = useParams()
  const { accountUsers } = useAccountContext()
  const accountUser = useMemo(() => accountUsers?.find((acctUser) => acctUser.id === accountUserId), [accountUsers, accountUserId])

  const [pauseDialogOpen, setPauseDialogOpen] = useState(false)
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false)
  const [ cancelSubscription, { loading: cancelLoading } ] = useCancelSubscription()
  const [ activateSubscription, { loading: activateLoading } ] = useActivateSubscription()

  const handleClickCancel = useCallback(() => {
    setCancelDialogOpen(true)
  }, [setCancelDialogOpen])

  const handleClickConfirm = useCallback(() => {
    cancelSubscription({ variables: { subscriptionId: accountUser.subscription.id } })
  }, [cancelSubscription, accountUser])

  const handleClickActivate = useCallback(() => {
    activateSubscription({ variables: { subscriptionId: accountUser.subscription.id } })
  }, [activateSubscription, accountUser])

  if (!accountUser || !accountUser.subscription) return null // TODO: redirect to /subscriptions/create

  return <Page>
    <Text variant="h4">Edit Subscription for {accountUser.user.displayName}</Text>

    { accountUser.subscription && accountUser.subscription.status === 'enabled' && <>
      <EditSubscriptionForm accountUser={accountUser} />

      <Box display="flex" justifyContent="flex-end">
        <Button variant="text" fullWidth={false} color="warning" loading={cancelLoading} disabled={cancelLoading} onClick={() => setPauseDialogOpen(true)}>Pause subscription</Button>

        <Button variant="text" fullWidth={false} color="error" loading={cancelLoading} disabled={cancelLoading} onClick={handleClickCancel}>Stop subscription</Button>
      </Box>

      <PauseSubscriptionDialog subscription={accountUser.subscription} open={pauseDialogOpen} onClose={() => setPauseDialogOpen(false)} />

      <ConfirmDialog
        open={cancelDialogOpen}
        onClose={() => setCancelDialogOpen(false)}
        content={<Text>Are you sure you want to stop this subscription? You can always reactivate it later.</Text>}
        action={<Button color="error" onClick={() => {
          handleClickConfirm()
          setCancelDialogOpen(false)
        }}>Stop subscription</Button>}
      />
    </> }

    { accountUser.subscription && accountUser.subscription.status === 'cancelled' && <>
      <Text my={4}>This subscription has been cancelled. You can use the button below if you&apos;d like to reactivate it.</Text>

      <Button color="secondary" loading={activateLoading} disabled={activateLoading} onClick={handleClickActivate}>Reactivate subscription</Button>
    </> }

    { accountUser.subscription && accountUser.subscription.status === 'paused' && <>
      <Text my={4}>This subscription has been paused. It will automatically resume on {DateTime.fromISO(accountUser.subscription.pauseUntil).toFormat('ccc, LLL d')}. You can use the button below if you&apos;d like to reactivate it sooner.</Text>

      <Button color="secondary" loading={activateLoading} disabled={activateLoading} onClick={handleClickActivate}>Reactivate subscription</Button>
    </> }


  </Page>
}

export default EditSubscription
