import React from 'react'
import { Page, Text, Box, Button } from 'components'
import { UseSubscriptionWizard } from '@market/hooks/useSubscriptionWizard'

export const StartStep: React.FC<{ wizard: UseSubscriptionWizard }> = ({ wizard }) => {
  return <Page unpadded noMargins>
    <Text variant="h4">{ wizard.activeStep.name }</Text>

    <Text variant="h6" my={2}>How it works</Text>
    <Text mb={2}>Once you&apos;ve activated your subscription, meals will be automatically added to your cart every Sunday. You can make changes or adjustments to your shopping cart until midnight on Thursdays, and we&apos;ll automatically check out at the end of the week.</Text>
    <Text mb={2}>You can still always checkout yourself if you&apos;d like. If you do order any meals manually, Ordo will make sure we don&apos;t double-up on orders for any given date.</Text>
    <Text mb={4}>We only deliver on school days, so you won&apos;t be charged during school breaks or days off.</Text>

    <Box>
      <Button disabled={!wizard.activeStep.completed(wizard.accountUser)} onClick={() => wizard.navigateToNext()}>Get started</Button>
    </Box>
  </Page>
}

export default StartStep
