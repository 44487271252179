import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  // Home as HomeIcon,
  Menu as MenuIcon,
  Orders as OrdersIcon,
  Account as AccountIcon,
  ManageAccount as ManageAccountIcon,
  Calendar as CalendarIcon,
} from 'icons'
import { Routes, RouteDefinitions } from 'compositions/Routes'
import { Login } from './Login'
import { ResetPassword, ForgotPassword } from './ResetPassword'
import { Register } from './Register'
// import { Home } from './Home'
import { Menus } from './Menus'
import { Orders } from './Orders'
import { Checkout } from './Checkout'
import {
  Account as AccountRoot,
  MyFamily,
  Settings as AccountSettings,
  Onboarding,
  Delinquent,
  Subscriptions,
  CreateSubscription,
  EditSubscription,
} from './Account'
import { Profile } from './Profile'
import { TasteProfiles, TasteProfile } from './TasteProfiles'
import { useMeQuery } from 'hooks/useMe'
import { Box, Text } from 'components'
import { useAccountContext } from '@market/hooks/useCurrentAccount'
import { Carts } from '@market/compositions/Carts'

export const routes: RouteDefinitions<{ subscriptionsEnabled: boolean }> = [
  // { path: '/', element: <Home />, name: 'Home', icon: HomeIcon, navMenu: true },
  { path: '/', element: <Menus />, name: 'Menus', icon: MenuIcon, navMenu: false },
  { path: '/menus', element: <Menus />, name: 'Menus', icon: MenuIcon, navMenu: true },
  { path: '/menus/*', element: <Menus />, name: 'Menus', icon: MenuIcon, navMenu: false },

  { path: '/orders', element: <Orders />, name: 'Orders', icon: OrdersIcon, navMenu: true },
  { path: '/account', element: <AccountRoot />, name: 'Account', icon: AccountIcon, navMenu: false, pathMatch: 'account' },
  { path: '/account/family', element: <MyFamily />, name: 'My Family', navMenu: false, parent: 'Account' },
  { path: '/account/settings', element: <AccountSettings />, name: 'Settings', navMenu: false, parent: 'Account' },
  { path: '/account/tastes', element: <TasteProfiles />, name: 'Taste Profiles', navMenu: false, parent: 'Account' },
  { path: '/account/tastes/:accountUserId', element: <TasteProfile />, name: 'Taste Profile', navMenu: false, parent: 'Taste Profiles' },
  { path: '/account/delinquent', element: <Delinquent />, name: 'Account Suspended', navMenu: false, parent: 'Account' },

  { path: '/subscriptions', element: <Subscriptions />, name: 'Subscriptions', icon: CalendarIcon, pathMatch: 'subscriptions', navMenu: (context) => context?.subscriptionsEnabled},
  { path: '/subscriptions/create/:accountUserId', element: <CreateSubscription />, name: 'Setup Subscription', navMenu: false, parent: 'Subscriptions' },
  { path: '/subscriptions/create/:accountUserId/*', element: <CreateSubscription />, name: 'Setup Subscription', navMenu: false, parent: 'Subscriptions' },
  { path: '/subscriptions/edit/:accountUserId', element: <EditSubscription />, name: 'Edit Subscription', navMenu: false, parent: 'Subscriptions' },

  { path: '/profile', element: <Profile />, name: 'Manage Account', icon: ManageAccountIcon, navMenu: false },

  { path: '/checkout', element: <Checkout />, name: 'Checkout', navMenu: false },

  { path: '/register', element: <Register />, name: 'Register', public: true },
  { path: '/register/*', element: <Register />, name: 'Register', public: true },

  { path: '/account/setup', element: <Onboarding />, name: 'Account Setup', public: false },
  { path: '/account/setup/*', element: <Onboarding />, name: 'Account Setup', public: false },

  { path: '/login', element: <Login />, name: 'Login', public: true, navMenu: false },
  { path: '/users/password/new', element: <ForgotPassword />, name: 'Forgot Password', public: true, navMenu: false },
  { path: '/users/password/edit', element: <ResetPassword />, name: 'Reset Password', public: true, navMenu: false },
  { path: '*', element: <Login />, name: 'Login', public: true, navMenu: false },
]

export const Router: React.FC = () => {
  const location = useLocation()
  const navigateTo = useNavigate()
  const { hasMarketAccess, me } = useMeQuery()
  const { account, needsOnboarding } = useAccountContext()

  useEffect(() => {
    if ((me && me.id && account && account.id) && account.delinquent && !location.pathname.match(/account\/delinquent.*/) && !location.pathname.match(/login.*/)) {
      navigateTo('/account/delinquent')
    }
  }, [me, account, location, navigateTo])

  // TODO: Move this logic into the wizard?
  useEffect(() => {
    if (needsOnboarding && !location.pathname.match(/account\/setup.*/) && !location.pathname.match(/login.*/)) {
      navigateTo('/account/setup')
    }
  }, [ me, needsOnboarding, location, navigateTo ])

  if ((needsOnboarding && !location.pathname.match(/account\/setup.*/) && !location.pathname.match(/login.*/)) || ((me && me.id && account && account.id) && account.delinquent && !location.pathname.match(/account\/delinquent.*/) && !location.pathname.match(/login.*/))) return null

  if (hasMarketAccess || !me) {
    return <>
      { !!account?.id && !needsOnboarding && <Carts /> }
      <Routes appName="Order Meals" routes={routes} />
    </>
  } else {
    return <Box>
      <Text>This app is unavailable.</Text>
    </Box>
  }
}

export default Router
