import { useCallback, useMemo } from "react"
import { useNavigate } from 'react-router-dom'
import { IconProps } from '@mui/material'
import { AccountUser } from "@market/graphql/schema/graphql"

export type Step = 'start' | 'payment' | 'options' | 'profile' | 'activate'

export type WizardStep<S = Step> = {
  step: S
  name: string
  available: (accountUser: AccountUser) => boolean
  completed: (accountUser: AccountUser) => boolean
}

export type StepComponents = {
  [key in Step]: React.FC<{ wizard: ReturnType<typeof useSubscriptionWizard> }>
}

export type StepIcons = {
  [key in Step]: React.FC<IconProps>
}

export const steps: WizardStep[] = [
  {
    step: 'start',
    name: 'Start a Subscription',
    available: () => true,
    completed: () => true,
  },
  {
    step: 'payment',
    name: 'Pick a Payment Method',
    available: () => true,
    completed: (accountUser) => !!accountUser.subscription,
  },
  {
    step: 'options',
    name: 'Subscription Options',
    available: (accountUser) => !!accountUser.subscription,
    completed: (accountUser) => !!accountUser.subscription, // TODO: better check for this (maybe new status?)
  },
  {
    step: 'profile',
    name: 'Taste Profile',
    available: (accountUser) => !!accountUser.subscription,
    completed: (accountUser) => !!accountUser.subscription && accountUser.user.reactions.length > 0,
  },
  {
    step: 'activate',
    name: 'Activate Subscription',
    available: (accountUser) => !!accountUser.subscription && accountUser.user.reactions.length > 0,
    completed: (accountUser) => accountUser.subscription?.status === 'enabled',
  },
]

export type UseSubscriptionWizard = ReturnType<typeof useSubscriptionWizard>

export const useSubscriptionWizard = ({ accountUser, step }: { accountUser: AccountUser, step: Step }) => {
  const navigateTo = useNavigate()
  const activeStep = useMemo(() => steps.find((s) => s.step === step), [step])
  const stepNumber = useMemo(() => steps.findIndex((s) => s.step === step) || 0, [step])
  const nextStep = useMemo(() => stepNumber === steps.length ? null : steps[stepNumber + 1], [stepNumber])
  const prevStep = useMemo(() => stepNumber === 0 ? null : steps[stepNumber - 1], [stepNumber])

  const navigateToNext = useCallback(() => {
    if (nextStep) {
      navigateTo(`/subscriptions/create/${accountUser.id}/${nextStep.step}`, { replace: true })
    }
  }, [ accountUser, nextStep, navigateTo ])

  const navigateToPrev = useCallback(() => {
    if (prevStep) {
      navigateTo(`/subscriptions/create/${accountUser.id}/${prevStep.step}`, { replace: true })
    } else {
      navigateTo(`/subscriptions/create/${accountUser.id}`, { replace: true })
    }
  }, [ accountUser, prevStep, navigateTo ])

  return {
    accountUser,
    steps,
    step,
    stepNumber,
    activeStep,
    prevStep,
    nextStep,
    navigateToNext,
    navigateToPrev,
  }
}

export default useSubscriptionWizard