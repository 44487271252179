export const lightGraphite = {
  50: '#fafafa',
  100: '#f4f4f4',
  200: '#ededed', // primary
  300: '#dfdfdf',
  400: '#bbbbbb',
  500: '#9c9c9c',
  600: '#737373',
  700: '#606060',
  800: '#414141',
  900: '#202020',
  A100: '#f4f4f4',
  A200: '#ededed',
  A400: '#bbbbbb',
  A700: '#606060',
}

export default lightGraphite
