import lightBlue from './lightBlue'
import darkBlue from './darkBlue'

export const blue = {
  50: '#e7ebfd',
  100: '#c3ccfa',
  200: '#99abf8',
  300: '#6889f5',
  400: '#396ef3', // primary
  500: '#0053ef',
  600: '#004ae3',
  700: '#003fd7',
  800: '#0034cc',
  900: '#001fb4',
  A100: '#c3ccfa',
  A200: '#99abf8',
  A400: '#396ef3',
  A700: '#003fd7',
}

export const palette = {
  main: blue[400],
  // light: lightBlue[50],
  // dark: darkBlue[600],
  // contrastText: '#ffffff',
}

export { lightBlue, darkBlue }

export default blue
