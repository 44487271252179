export const darkBlue = {
  50: '#e7e9f9',
  100: '#c2c8f0',
  200: '#99a5e5',
  300: '#6e82dc',
  400: '#4a66d4',
  500: '#184acc',
  600: '#1042c1', // primary
  700: '#0038b5',
  800: '#002daa',
  900: '#001997',
  A100: '#c2c8f0',
  A200: '#99a5e5',
  A400: '#4a66d4',
  A700: '#0038b5',
}

export default darkBlue
