export const darkYellow = {
  50: '#fdf8e4',
  100: '#f8edbb',
  200: '#f2e38f',
  300: '#ebd863',
  400: '#e5d03f',
  500: '#dfc90e',
  600: '#d0b80a',
  700: '#bda303',
  800: '#a98d00', // primary
  900: '#896800',
  A100: '#f8edbb',
  A200: '#f2e38f',
  A400: '#e5d03f',
  A700: '#bda303',
}

export default darkYellow
