import type { ResolveFailedPaymentsInput, ResolveFailedPaymentsPayload } from '@market/graphql/schema/graphql'
import { resolveFailedPaymentsMutation } from '@market/graphql/mutations'
import { accountQuery } from '@market/graphql/queries/account'
import { MutationTuple, useMarketMutation } from './useMarketMutation'

export type ResolveFailedPaymentsFn = ReturnType<typeof useResolveFailedPayments>[0]

export const useResolveFailedPayments: (variables?: ResolveFailedPaymentsInput) => MutationTuple<ResolveFailedPaymentsPayload, ResolveFailedPaymentsInput> = (variables)  => {
  const [ resolveFailedPaymentsFn, { data, ...result } ] = useMarketMutation<ResolveFailedPaymentsPayload, ResolveFailedPaymentsInput>(
    resolveFailedPaymentsMutation,
    {
      variables,
      refetchQueries: [accountQuery],
      awaitRefetchQueries: true,
    }
  )
  // TODO: update cached user reactions

  return [ resolveFailedPaymentsFn, { ...result, data } ]
}

export default useResolveFailedPayments
