import React, { useMemo, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
  Box,
  Button,
  Card,
  CardContent,
  Text,
  Link,
} from 'components'
import { Account, AccountUser } from '@market/graphql/schema/graphql'
import { PauseSubscriptionDialog } from '@market/compositions/Subscriptions/PauseSubscriptionDialog'
import { DateTime } from 'luxon'

export const SubscriptionCard: React.FC<{ account: Account, user: AccountUser }> = ({ user }) => {
  const [pauseDialogOpen, setPauseDialogOpen] = useState(false)

  const activeDays = useMemo(() => 
    ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'].filter((day) =>
      !!user?.subscription?.dailyOptions[day]?.enabled && !!user?.subscription?.dailyOptions[day]?.active
    ),
  [user?.subscription])

  return <Box key={user.id}>
    <Card sx={(theme) => ({
      [theme.breakpoints.up('md')]: {
        width: theme.spacing(96),
      },
      [theme.breakpoints.up('lg')]: {
        width: theme.spacing(96),
      },
    })}>
      <CardContent sx={{
        padding: 0,
        '&:last-child': {
          paddingBottom: 0,
        }
      }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" p={4}>
          <Box>
            <Text variant="h6">{ user.user.preferredName || user.user.firstName }</Text>
            { (user.subscription && user.subscription.status === "enabled") && <Text>Active {activeDays.length} days a week.</Text> }
            { (user.subscription && user.subscription.status === "paused") && <Text color="grey">Paused until {DateTime.fromISO(user?.subscription?.pauseUntil).toFormat('ccc, LLL d')}.</Text> }
          </Box>

          <Box>
            { (!user.subscription || user.subscription.status === "initialized") && <Button component={RouterLink} to={`/subscriptions/create/${user.id}`} color="secondary" size="large" fullWidth={false}>Subscribe</Button> }
            { (user.subscription && user.subscription.status === "cancelled") && <Button component={RouterLink} to={`/subscriptions/edit/${user.id}`} color="secondary" size="large" fullWidth={false}>Subscribe</Button> }
            { (user.subscription && (user.subscription.status === "enabled" || user.subscription.status === "paused")) && <Button component={RouterLink} to={`/subscriptions/edit/${user.id}`} size="large" fullWidth={false}>Change Plan</Button> }
          </Box>
        </Box>

        { (user.subscription && user.subscription.status === "enabled") && <Box sx={(theme) => ({ px: 4, py: 2, borderTopWidth: '1px', borderTopStyle: 'solid', borderTopColor: theme.palette.divider })}>
          <Text>Traveling? Need a break? It&apos;s easy to <Link onClick={() => setPauseDialogOpen(true)} sx={{ cursor: 'pointer' }}>skip your order.</Link></Text>

          <PauseSubscriptionDialog subscription={user.subscription} open={pauseDialogOpen} onClose={() => setPauseDialogOpen(false)} />
        </Box> }
      </CardContent>
    </Card>
  </Box>
}

export default SubscriptionCard
