export const darkGreen = {
  50: '#e7f7ed',
  100: '#c6ebd2',
  200: '#a2deb6',
  300: '#7bd298',
  400: '#5bc882',
  500: '#39be6c',
  600: '#31ae61',
  700: '#279b54',
  800: '#1e8a49',
  900: '#0c6a35', // primary
  A100: '#c6ebd2',
  A200: '#a2deb6',
  A400: '#5bc882',
  A700: '#279b54',
}

export default darkGreen
