import { useEffect, useMemo } from 'react'
import { DateTime, Interval } from 'luxon'
import { Filter, FilterProps as BaseFilterProps, setupContext, useFilters } from 'hooks/useFilters'
import { Account, Order, SchoolLocation, Tag, QueryFilterOperatorEnum, QueryFilterLogicOperatorEnum } from '@market/graphql/schema/graphql'
import { useAccountOrders } from './useAccountOrders'

export type MenuTypeMap = {
  "Menus::BreakfastMenu": "Breakfast",
  "Menus::AmSnackMenu": "AM Snack",
  "Menus::LunchMenu": "Lunch",
  "Menus::PmSnackMenu": "PM Snack",
  "Menus::DinnerMenu": "Dinner",
}

export const menuTypeMap: MenuTypeMap = {
  "Menus::BreakfastMenu": "Breakfast",
  "Menus::AmSnackMenu": "AM Snack",
  "Menus::LunchMenu": "Lunch",
  "Menus::PmSnackMenu": "PM Snack",
  "Menus::DinnerMenu": "Dinner",
}

export interface FilterState {
  timeZone: string
  startDate: DateTime
  endDate: DateTime
  filteredDate: DateTime
  locationIds: string[]
  userIds: string[]
  statuses: string[]
  dietaryTagIds: string[]
  menuTypes: string[] // (MenuTypeMap["Menus::LunchMenu"] | MenuTypeMap["Menus::DinnerMenu"] | MenuTypeMap["Menus::BreakfastMenu"])[]
}

export type FilterProps = Pick<
  BaseFilterProps<FilterState, Order>,
  | "filters"
  | "appliedFilters"
  | "filterDefinitions"
  | "setFilters"
  | "applyFilters"
  | "setAndApplyFilters"
  | "clearFilters"
  | "applicable"
  | "clearable"
> & Pick<ReturnType<typeof useAccountOrders>, "loading"> & {
  availableDietaryTags: Tag[]
  availableTemperatureTags: Tag[]
  availableMenuTypes: [string, string][]
  availableLocations: SchoolLocation[]
  availableDates: DateTime[]
}

const timeZoneFilter: Filter<FilterState, Order, 'timeZone'> = {
  name: 'timeZone',
  label: 'Time Zone',
}

const startDateFilter: Filter<FilterState, Order, 'startDate'> = {
  name: 'startDate',
  label: 'Start Date',
  match: (order, { startDate, timeZone }) => {
    if (!startDate) return true
    return DateTime.fromISO(order.deliveryDate).setZone(timeZone) >= startDate.setZone(timeZone).startOf('day')
  },
}

const endDateFilter: Filter<FilterState, Order, 'endDate'> = {
  name: 'endDate',
  label: 'End Date',
  match: (order, { endDate, timeZone }) => {
    if (!endDate) return true
    return DateTime.fromISO(order.deliveryDate).setZone(timeZone) <= endDate.setZone(timeZone).endOf('day')
  },
}

const filteredDateFilter: Filter<FilterState, Order, 'filteredDate'> = {
  name: 'filteredDate',
  label: 'Selected Date',
  match: (order, { filteredDate, timeZone }) => {
    if (!filteredDate) return true
    return DateTime.fromISO(order.deliveryDate).setZone(timeZone).toISO() === filteredDate.setZone(timeZone).toISO()
  },
}

const locationFilter: Filter<FilterState, Order, 'locationIds'> = {
  name: 'locationIds',
  label: 'Location',
  match: (order, { locationIds }) => {
    return true
    if (locationIds.length === 0) return true
    return locationIds.includes(order.orderProducts[0]?.menuProduct?.menu?.location?.id)
  },
}

const familyFilter: Filter<FilterState, Order, 'userIds'> = {
  name: 'userIds',
  label: 'Family',
  match: (order, { userIds }) => {
    if (userIds.length === 0) return true
    return userIds.includes(order.recipient.id)
  },
}

const statusFilter: Filter<FilterState, Order, 'statuses'> = {
  name: 'statuses',
  label: 'Order Status',
  match: (order, { statuses }) => {
    if (statuses.length === 0) return true
    return statuses.includes(order.displayStatus)
  },
}

const dietaryTagFilter: Filter<FilterState, Order, 'dietaryTagIds'> = {
  name: 'dietaryTagIds',
  label: 'Dietary',
  match: (order, { dietaryTagIds }) => {
    if (dietaryTagIds.length === 0) return true
    return dietaryTagIds.every((tagId) => order.orderProducts.flatMap((orderProduct) => orderProduct.menuProduct.product.tags)?.some((tag) => tag.id === tagId))
  },
}

const menuTypeFilter: Filter<FilterState, Order, 'menuTypes'> = {
  name: 'menuTypes',
  label: 'Mealtimes',
  match: (order, { menuTypes }) => {
    if (menuTypes.length === 0) return true
    return menuTypes.includes(order.orderProducts[0]?.menuProduct?.menu?.type)
  },
}

export const { Context, ContextProvider, useContext } = setupContext<ReturnType<typeof useFilteredAccountOrders>>()

export const useFilteredAccountOrders = (account: Account, locations: SchoolLocation[], initialState?: Partial<FilterState>) => {
  const timeZone = useMemo(() => {
    return locations[0]?.timeZone
  }, [locations])

  const startDate = useMemo(() => {
    if (initialState?.startDate) {
      return initialState?.startDate.setZone(timeZone)
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return DateTime.fromISO(DateTime.now().toISO()).setZone(timeZone).startOf('week', { useLocaleWeeks: true })
    }
  }, [initialState, timeZone])

  const endDate = useMemo(() => {
    if (initialState?.endDate) {
      return initialState?.endDate.setZone(timeZone)
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return startDate.plus({ weeks: 1 }).endOf('week', { useLocaleWeeks: true })
    }
  }, [initialState, timeZone, startDate])

  const filters = useFilters<FilterState, Order>(
    [
      timeZoneFilter,
      startDateFilter,
      endDateFilter,
      filteredDateFilter,
      locationFilter,
      familyFilter,
      statusFilter,
      dietaryTagFilter,
      menuTypeFilter,
    ],
    {
      timeZone: timeZone,
      startDate,
      endDate,
      filteredDate: undefined,
      locationIds: [...(initialState?.locationIds || [])],
      userIds: [...(initialState?.userIds || [])],
      statuses: [...(initialState?.statuses || [])],
      dietaryTagIds: [...(initialState?.dietaryTagIds || [])],
      menuTypes: [...(initialState?.menuTypes || [])],
    },
    [],
  )

  const { filterData, setFilterOptions, setAndApplyFilters } = filters

  const { data, ...rest } = useAccountOrders({
    accountId: account.id,
    filters: {
      filters: [
        { field: 'startDate', operator: QueryFilterOperatorEnum.GreaterOrEqual, value: [filters.appliedFilters.startDate.toISO()] },
        { field: 'endDate', operator: QueryFilterOperatorEnum.LessOrEqual, value: [filters.appliedFilters.endDate.toISO()] },
        { field: 'users', operator: QueryFilterOperatorEnum.Match, value: filters.appliedFilters.userIds },
      ],
      logicOperator: QueryFilterLogicOperatorEnum.And,
    },
    pollInterval: 6000000,
  })

  const availableLocations = useMemo(
    () => locations.filter((v, i, a) => a.indexOf(v) === i) || [],
    [locations]
  )

  const availableUsers = useMemo(
    () =>
      account.accountUsers?.filter((acctUser) => !!(acctUser.user.roster?.term?.location || acctUser.location))
      .filter((v, i, a) => a.indexOf(v) === i) || [],
    [account]
  )

  const availableStatuses = useMemo(
    () =>
      data?.accountOrders?.rows?.flatMap((order) => order.displayStatus)
      .filter((v, i, a) => a.indexOf(v) === i)
      .sort((a, b) => a.localeCompare(b)) || [],
    [data?.accountOrders]
  )

  const availableDietaryTags = useMemo(
    () =>
      data?.accountOrders?.rows?.flatMap((order) => order.orderProducts.flatMap((orderProduct) => orderProduct.menuProduct.product.tags))
      .filter((v, i, a) => a.indexOf(v) === i && v.type === 'dietary')
      .sort((a, b) => a.name.localeCompare(b.name)) || [],
    [data?.accountOrders]
  )

  const availableTemperatureTags = useMemo(
    () =>
      data?.accountOrders?.rows?.flatMap((order) => order.orderProducts.flatMap((orderProduct) => orderProduct.menuProduct.product.tags))
      .filter((v, i, a) => a.indexOf(v) === i && v.type === 'temperature')
      .sort((a, b) => a.name.localeCompare(b.name)) || [],
    [data?.accountOrders]
  )

  const availableMenuTypes = useMemo(
    () =>
      (data?.accountOrders?.rows?.flatMap((order) => order.orderProducts.map((orderProduct) => [orderProduct.menuProduct.menu.type, menuTypeMap[orderProduct.menuProduct.menu.type]]))
      .filter((v, i, a) => a.indexOf(v) === i)
      .sort((a, b) => a[1].localeCompare(b[1])) || []) as [string, string][],
    [data?.accountOrders]
  )

  const availableDates = useMemo(
    () =>
      data?.accountOrders?.rows?.map((order) => order.deliveryDate)
      .filter((v, i, a) => a.indexOf(v) === i)
      .map((date) => DateTime.fromISO(date).setZone(timeZone))
      .sort((a, b) => a <= b ? -1 : 1) || [],
    [data?.accountOrders, timeZone]
  )

  useEffect(() => {
    setFilterOptions('locationIds', availableLocations.map((loc) => ({ key: loc.displayName, value: loc.id })))
    setFilterOptions('userIds', availableUsers.map((acctUser) => ({ key: acctUser.user.displayName, value: acctUser.user.id })))
    setFilterOptions('statuses', availableStatuses.map((status) => ({ key: status, value: status })))
    setFilterOptions('dietaryTagIds', availableDietaryTags.map((tag) => ({ key: tag.name, value: tag.id })))
    setFilterOptions('menuTypes', Object.keys(menuTypeMap).map((menuType) => ({ key: menuTypeMap[menuType], value: menuType, disabled: !availableMenuTypes.some((amt) => amt[0] === menuType) })))
    // if (appliedFilters.filteredDate !== undefined && !availableDates.map(date => date.toISO()).includes(appliedFilters.filteredDate?.toISO())) {
    //   setAndApplyFilters({ filteredDate: availableDates[0] })
    // }
  }, [ setFilterOptions, setAndApplyFilters, availableLocations, availableUsers, availableStatuses, availableDietaryTags, availableTemperatureTags, availableMenuTypes/*, availableDates, appliedFilters.filteredDate*/ ])

  const filteredAccountOrders = useMemo(() => filterData(data.accountOrders.rows), [filterData, data.accountOrders])

  const groupedDates: { [x: string]: Order[] } = useMemo(() => {
    const dateRange = Interval.fromDateTimes(filters.appliedFilters.startDate, filters.appliedFilters.endDate)

    return dateRange.splitBy({ day: 1 }).map(d => d.start).reduce((memo, d) => {
      memo[d.toFormat('yyyy-LL-dd')] ||= []
      return memo
    }, {})
  }, [filters.appliedFilters])

  const groupedAccountOrders: { [x: string]: Order[] } = useMemo(() => {
    return filteredAccountOrders.reduce((memo, order) => {
      if (!!memo[order.deliveryDate]) {
        const orderIndex = memo[order.deliveryDate].findIndex((o) => o.id === order.id)
        if (orderIndex >= 0) {
          memo[order.deliveryDate][orderIndex] = order
        } else {
          memo[order.deliveryDate].push(order)
        }
      }

      return memo
    }, groupedDates)
  }, [filteredAccountOrders, groupedDates])

  return {
    ...rest,
    data: {
      ...data,
      filteredAccountOrders,
      groupedAccountOrders,
    },
    filters: {
      ...filters,
      availableDates,
      availableLocations,
      availableUsers,
      availableStatuses,
      availableMenuTypes,
      availableDietaryTags,
      availableTemperatureTags,
    },
  }
}

export default useFilteredAccountOrders
