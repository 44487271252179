import type { CancelSubscriptionInput, CancelSubscriptionPayload } from '@market/graphql/schema/graphql'
import { cancelSubscriptionMutation } from '@market/graphql/mutations'
import { MutationTuple, useMarketMutation } from './useMarketMutation'

export type CancelSubscriptionMutationData = { cancelSubscription: CancelSubscriptionPayload }

export const useCancelSubscription: (variables?: CancelSubscriptionInput) => MutationTuple<CancelSubscriptionMutationData, CancelSubscriptionInput> = (variables)  => {
  const [ cancelSubscriptionFn, { data, ...result } ] = useMarketMutation<CancelSubscriptionMutationData, CancelSubscriptionInput>(
    cancelSubscriptionMutation,
    {
      variables,
    }
  )

  return [ cancelSubscriptionFn, { ...result, data } ]
}

export default useCancelSubscription
