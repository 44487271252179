import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Box,
  Chip,
} from 'components'
import { useContext } from '@market/hooks/useFilteredAvailableMenuProducts'
import { LocationFilter } from './LocationFilter'
import { DateRangeFilter } from './DateRangeFilter'
import { OtherFilters } from './OtherFilters'

export const DateSelector: React.FC = () => {
  const navigateTo = useNavigate()
  const location = useLocation()
  const { filters: { fullAvailableDates, appliedFilters, setAndApplyFilters} } = useContext()

  useEffect(() => {
    if (appliedFilters.filteredDate === undefined && appliedFilters.filteredDate !== location.state?.filteredDate) {
      navigateTo('/menus', { state: { filteredDate: undefined } })
    }
  }, [location, appliedFilters.filteredDate, navigateTo])

  return <Box display="flex" alignItems="center" gap={2}>
    { fullAvailableDates.sort((a, b) => a.toMillis() - b.toMillis() ).filter((date) => date >= appliedFilters.startDate && date <= appliedFilters.endDate).map((date) => {
      const selected = date.setZone(appliedFilters.timeZone).toISO() === appliedFilters.filteredDate?.setZone(appliedFilters.timeZone)?.toISO()

      return <Chip
        key={date.toISO()}
        label={date.toFormat('ccc, LLL d')}
        sx={(theme) => ({
          fontSize: '1rem',
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          backgroundColor: theme.palette.background.default,
          borderBottomWidth: '4px',
          borderBottomStyle: 'solid',
          borderBottomColor: selected ? theme.palette.primary.main : theme.palette.background.default,
          '&:hover': {
            backgroundColor: theme.palette.background.default,
            [theme.breakpoints.up('lg')]: {
              borderBottomColor: theme.palette.primary.main,
            },
          },
        })}
        onClick={() => {
          if (selected) {
            setAndApplyFilters({ filteredDate: undefined })
            navigateTo('/menus', { state: { filteredDate: undefined } })
          } else {
            setAndApplyFilters({ filteredDate: date })
            navigateTo(`/menus`, { state: { filteredDate: date.toFormat('yyyy-LL-dd') } })
          }
        }}
      />
    }) }
  </Box>
}

export const MenuFilters: React.FC = () => {
  return <Box>
    <Box display="flex" alignItems="center" gap={2} mb={2}>
      <DateRangeFilter />
      <OtherFilters />
      <LocationFilter />
    </Box>

    <Box display={{ xs: 'none', md: 'block' }}>
      <DateSelector />
    </Box>
   </Box>
}

export default MenuFilters
