export const darkMagenta = {
  50: '#fae4ee',
  100: '#f3bad6',
  200: '#ed8eba',
  300: '#e8609e',
  400: '#e53c88',
  500: '#e30e72',
  600: '#d20f6e',
  700: '#bc1067',
  800: '#a60f62', // primary
  900: '#800d58',
  A100: '#f3bad6',
  A200: '#ed8eba',
  A400: '#e53c88',
  A700: '#bc1067',
}

export default darkMagenta
