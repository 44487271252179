import React from 'react'
import { Box, BoxProps } from 'components/Box'
import { EmptyState } from 'components'
import { Spinner } from 'components/Loading'
import { Menu as MenuIcon } from 'icons'
import { AccountUser } from '@market/graphql/schema/graphql'
import { useFilteredAvailableProducts } from '@market/hooks'
import { ProductGrid } from './ProductGrid'
import { ProductFilters } from './ProductFilters'

export const TasteProfile: React.FC<BoxProps & { accountUser: AccountUser }> = ({ accountUser, ...boxProps }) => {
  const {
    loading, // TODO: Show loading state
    filters,
    data: { filteredProducts }
  } = useFilteredAvailableProducts(accountUser)

  return <Box {...boxProps}>
    <ProductFilters
      loading={loading}
      {...filters}
    />

    { loading && filteredProducts.length === 0 && <Spinner message="Loading Items..." /> }
    { (!loading || filteredProducts.length > 0) && <ProductGrid accountUser={accountUser} products={filteredProducts} /> }
    { !loading && filteredProducts.length === 0 && <EmptyState icon={MenuIcon} message="No items to display." /> }
  </Box>
}

export default TasteProfile
