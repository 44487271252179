import lightMagenta from './lightMagenta'
import darkMagenta from './darkMagenta'

export const magenta = {
  50: '#fbe6f2',
  100: '#f5bfdf',
  200: '#f296ca',
  300: '#f16cb4',
  400: '#f14ba1',
  500: '#f3288d',
  600: '#e12788',
  700: '#c92680', // primary
  800: '#b32479',
  900: '#8b206d',
  A100: '#f5bfdf',
  A200: '#f296ca',
  A400: '#f14ba1',
  A700: '#c92680',
}

export const palette = {
  main: magenta[700],
  // light: lightMagenta[50],
  // dark: darkMagenta[800],
  // contrastText: '#ffffff',
}

export { lightMagenta, darkMagenta }

export default magenta
