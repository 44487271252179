import React, { useCallback, useMemo, useState } from 'react'
import { styled } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { Page, Button, Box, Text, Currency } from 'components'
import { useStripeAddPaymentModal, useResolveFailedPayments } from '@market/hooks'
import { useAccountContext } from '@market/hooks/useCurrentAccount'
import { PaymentMethodSelector, OrderSummary } from '@market/components'
import { AddPaymentMethodModal } from '@market/compositions/StripePayments'
import { DateTime } from 'luxon'

const OrderSummaries = styled(Box)(({ theme }) => ({
  '& .OrdoOrderSummary-cards': {
    [theme.breakpoints.up('md')]: {
      gap: theme.spacing(2),
    },
  },

  '& .OrdoProductQuantityCard-root': {
    [theme.breakpoints.up('md')]: {
      width: '45%',
      maxWidth: '45%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '45%',
      maxWidth: '45%',
    },  
  }
}))

export const Delinquent: React.FC = () => {
  const { paymentMethods, setupIntent, account: { id: accountId, delinquentFor, delinquentBatches: delinquentAccountBatches } } = useAccountContext()
  const delinquentBatches = useMemo(() => delinquentAccountBatches?.filter((batch) => batch.status === 'failed' && batch.outstandingCents > 0) || [], [delinquentAccountBatches])
  const [ paymentMethodId, setPaymentMethodId ] = useState(paymentMethods.find((pm) => !!pm.default )?.stripeId || null)
  const modal = useStripeAddPaymentModal(setupIntent?.clientSecret)
  const { openModal } = modal

  const [ resolveFailedPayments, { loading }] = useResolveFailedPayments()
  const handleResolvePayments = useCallback(() => {
    resolveFailedPayments({ variables: { accountId, batchIds: delinquentBatches.map(batch => batch.id), paymentMethodId } })
  }, [resolveFailedPayments, accountId, delinquentBatches, paymentMethodId])

  const orders = useMemo(() => delinquentBatches?.flatMap((batch) => batch.orders).filter((order) => order.outstandingCents > 0), [delinquentBatches])
  const groupedOrders = orders.sort((a, b) => DateTime.fromISO(a.deliveryDate).toMillis() - DateTime.fromISO(b.deliveryDate).toMillis()).reduce((memo, order) => {
    memo[order.deliveryDate] ||= []
    memo[order.deliveryDate].push(order)
    return memo
  }, {})

  if (!!accountId && (!delinquentFor || delinquentFor === 0)) {
    return <Page>
      <Text variant="h4" mb={2}>Your account is up-to-date</Text>

      <Text mb={2}>You do not have any missed payments at this time. Thank you for keeping your Ordo account current!</Text>

      <Button component={RouterLink} to={'/menu'}>Go to menu</Button>
    </Page>
  } else if (!!accountId && delinquentFor > 0) {
    return <Page>
      <Text variant="h4" mb={2}>Account suspended</Text>

      <Text>Your account has been suspended due to a payment issue. Immediate action is required to reinstate your account.</Text>

      <Box sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        my: 2,
        py: 2,
        px: 2,
        borderTopWidth: '1px',
        borderBottomWidth: '1px',
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderStyle: 'solid',
        borderColor: theme.palette.divider,
      })}>
        <Text variant="h5" fontWeight="medium">Past-due amount:</Text>
        <Text ml="auto" variant="h6" fontWeight="normal"><Currency value={delinquentBatches?.reduce((memo, batch) => memo += batch.outstandingCents, 0) / 100} /></Text>
      </Box>

      <Text mb={2}>Please resolve the outstanding balance by either retrying with an existing payment method or adding a new one.</Text>

      { paymentMethods.length > 0 && <PaymentMethodSelector
        paymentMethods={paymentMethods}
        value={paymentMethodId}
        disabled={loading}
        onChange={(evt) => setPaymentMethodId(evt.target.value)}
      /> }

      <Box mt={2}>
        <Button onClick={openModal} fullWidth={false} disabled={loading} color="secondary">Add payment method</Button>
      </Box>

      <Box mt={2}>
        <Button disabled={loading || !paymentMethodId} loading={loading} onClick={handleResolvePayments}>Pay now</Button>
      </Box>

      <AddPaymentMethodModal {...modal} />

      <Box sx={(theme) => ({
        my: 2,
        pt: 2,
        borderTopWidth: '1px',
        borderBottomWidth: '1px',
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderStyle: 'solid',
        borderColor: theme.palette.divider,
      })}>
        <Text variant="h5" fontWeight="medium" mb={2}>Past-due orders:</Text>
        <OrderSummaries>
          { Object.keys(groupedOrders).flatMap((groupDate) =>
            groupedOrders[groupDate].map((order) => <OrderSummary key={order.id} order={order} />)
          ) }
        </OrderSummaries>
      </Box>
    </Page>
  } else {
    return null
  }
}

export default Delinquent
