import React, { useCallback, useMemo } from 'react'
import {
  Box,
  Button,
  Text,
  CheckboxFilter,
} from 'components'
import {
  FiltersPopover,
  FiltersPopoverHeader,
  FiltersPopoverContent,
  FiltersPopoverFooter,
} from 'components/Filters'
import { usePopoverState, useViewport } from 'hooks'
import { Filters as FiltersIcon } from 'icons'
import { FilterState, FilterProps, useContext } from '@market/hooks/useFilteredAccountOrders'

export type MenuFiltersProps = Partial<FilterProps> & {
  filters: FilterState
}

export const OtherFilters: React.FC = () => {
  const { isMedium } = useViewport()
  const { filters: { filters, filterDefinitions, appliedFilters, setFilters, applyFilters, clearFilters } } = useContext()
  const { open, anchorElement, handleClick, handleClose } = usePopoverState()
  const appliedFiltersCount = useMemo(() => {
    return appliedFilters.dietaryTagIds.length + appliedFilters.statuses.length
  }, [appliedFilters])

  const applyAndClose = useCallback(() => {
    applyFilters()
    handleClose()
  }, [applyFilters, handleClose])

  const clearAndClose = useCallback(() => {
    clearFilters({ clear: ['dietaryTagIds', 'statuses', 'menuTypes'] })
    handleClose()
  }, [clearFilters, handleClose])

  return <Box>
    <Button onClick={handleClick} variant="outlined" fullWidth={false}>
      <FiltersIcon />
      { appliedFiltersCount > 0 && <Text fontWeight="bold" ml={1}>({ appliedFiltersCount })</Text> }
    </Button>
    <FiltersPopover
      open={open}
      anchorEl={anchorElement}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      sx={{ marginTop: { md: 1 } }}
    >
      <FiltersPopoverHeader onClose={handleClose}>
        <Text variant="h6">More Filters</Text>
      </FiltersPopoverHeader>

      <FiltersPopoverContent>
        <CheckboxFilter filterName="statuses" filterDefinitions={filterDefinitions} filters={filters} setFilters={setFilters} row />
        <CheckboxFilter filterName="menuTypes" filterDefinitions={filterDefinitions} filters={filters} setFilters={setFilters} row />
        <CheckboxFilter filterName="dietaryTagIds" filterDefinitions={filterDefinitions} filters={filters} setFilters={setFilters} row />
      </FiltersPopoverContent>

      <FiltersPopoverFooter sx={(theme) => ({
        display: 'flex',
        gap: 1,
        [theme.breakpoints.up('xs')]: {
          flexDirection: 'column',
        },
        [theme.breakpoints.up('md')]: {
          flexDirection: 'row',
        },
      })}>
        <Box width={isMedium ? undefined : '100%'}>
          <Button fullWidth={!isMedium} onClick={applyAndClose}>Apply</Button>
        </Box>
        <Box width={isMedium ? undefined : '100%'}>
          <Button fullWidth={!isMedium} variant="outlined" onClick={clearAndClose}>Clear</Button>
        </Box>
      </FiltersPopoverFooter>
    </FiltersPopover>
  </Box>
}

export default OtherFilters
