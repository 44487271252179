import { gql } from '@market/graphql/schema/gql'

export const resolveFailedPaymentsMutation = gql(`
  mutation resolveFailedPaymentsMutation($input: ResolveFailedPaymentsInput! ) {
    resolveFailedPayments(input: $input) {
      orderBatches {
        ...OrderBatchFields
      }
      errors {
        code
        message
        path
      }
    }
  }
`)

export default resolveFailedPaymentsMutation
