import { gql } from '@market/graphql/schema/gql'

export const accountQuery = gql(`
  query accountQuery($accountId: ID!) {
    account(accountId: $accountId) {
      id
      name
      settings
      delinquent
      delinquentFor
      delinquentBatches {
        ...OrderBatchFields
      }
      accountUsers {
        ...AccountUserFields
      }
    }
  }
`)

export default accountQuery
