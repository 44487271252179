import { gql } from '@market/graphql/schema/gql'

export const servicedSchoolsQuery = gql(`
  query servicedSchoolsQuery($state: String, $locationId: String, $query: String, $limit: Int) {
    servicedSchools(state: $state, locationId: $locationId, query: $query, limit: $limit) {
      id
      name
      locationId
      createdAt
      updatedAt
      location {
        id
        settings
      }
      terms {
        id
        name
        rosters {
          id
          name
          description
          displayName
          group
          groupName
          sortOrder
          isVisible
          isArchived
        }
      }
    }
  }
`)

export default servicedSchoolsQuery
