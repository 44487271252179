import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, Link as RouterLink } from 'react-router-dom'
import { DateTime } from 'luxon'
import { styled } from '@mui/material'
import {
  Box,
  Page,
  Text,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Currency,
  Button,
  Link,
  EmptyState,
} from 'components'
import { ArrowBack, Close as CloseIcon, Cart as CartIcon } from 'icons'
import { useViewport, useMe } from 'hooks'
import { Account, Cart, StripePaymentMethod, StripeSetupIntent } from '@market/graphql/schema/graphql'
import { UserCart } from '@market/compositions/Carts'
import { PaymentMethodSelector, OrderSummary } from '@market/components'
import { useStripeAddPaymentModal, useCheckoutCarts } from '@market/hooks'
import { AddPaymentMethodModal } from '@market/compositions/StripePayments'

const OrderSummaries = styled(Box)(({ theme }) => ({
  '& .OrdoOrderSummary-cards': {
    [theme.breakpoints.up('md')]: {
      gap: theme.spacing(2),
    },
  },

  '& .OrdoProductQuantityCard-root': {
    [theme.breakpoints.up('md')]: {
      width: '45%',
      maxWidth: '45%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '45%',
      maxWidth: '45%',
    },  
  }
}))

export const Checkout: React.FC<{ account: Account, carts: Cart[], paymentMethods: StripePaymentMethod[], setupIntent: StripeSetupIntent }> = ({ account, carts, paymentMethods, setupIntent }) => {
  const { isMedium } = useViewport()
  const navigateTo = useNavigate()
  const { me } = useMe()
  
  const [ paymentMethodId, setPaymentMethodId ] = useState(paymentMethods.find((pm) => !!pm.default )?.stripeId || '')
  const stripeModal = useStripeAddPaymentModal(setupIntent?.clientSecret)

  const [ checkoutCarts, { loading, called, errors, data: { checkoutCarts: { orders } } } ] = useCheckoutCarts({ accountId: account.id, paymentMethodId })
  const submitted = useMemo(() => called && !loading && (!errors || errors.length === 0), [called, loading, errors])
  const groupedOrders = orders.sort((a, b) => DateTime.fromISO(a.deliveryDate).toMillis() - DateTime.fromISO(b.deliveryDate).toMillis()).reduce((memo, order) => {
    memo[order.deliveryDate] ||= []
    memo[order.deliveryDate].push(order)
    return memo
  }, {})

  const subtotalCents = useMemo(() => carts.reduce((memo, cart) => memo + cart.subtotalCents, 0), [carts])
  const taxesCents = useMemo(() => carts.reduce((memo, cart) => memo + cart.taxesCents, 0), [carts])
  const totalCents = useMemo(() => carts.reduce((memo, cart) => memo + cart.totalCents, 0), [carts])
  const totalQty = useMemo(() => carts.reduce((memo, cart) => memo + cart.quantity, 0), [carts])
  const cartsValid = useMemo(() => totalQty > 0 && carts.every((cart) => cart.cartItems.every((item) => item.validation.valid)), [carts, totalQty])
  const cartsEmpty = useMemo(() => carts.every((cart) => cart.cartItems.length === 0), [carts])

  useEffect(() => {
    if (!paymentMethodId || paymentMethodId === '') setPaymentMethodId(paymentMethods.find((pm) => !!pm.default )?.stripeId || '')
  }, [paymentMethodId, paymentMethods])

  return <Page unpadded>
    <Box textAlign="center" my={2}>
      <Text variant="h5">Checkout</Text>
    </Box>

    { submitted && orders.length > 0 && <Box sx={{ p: 0 }}>
      <Box mb={4} px={2}>
        <Text pb={2} variant="h3">Thank you, {me.preferredName || me.firstName}!</Text>
        <Text pb={2} variant="h6" fontWeight="normal">Your orders have been placed.</Text>
        <Text pb={2} variant="h6" fontWeight="normal">If you want to review your orders you can <Link component={RouterLink} to="/orders" replace>check your order history here</Link>.</Text>

        <Button component={RouterLink} to="/menus">Go to menu</Button>
      </Box>

      <OrderSummaries>
        { Object.keys(groupedOrders).flatMap((groupDate) =>
          groupedOrders[groupDate].map((order) => <OrderSummary key={order.id} order={order} interactive={false} />)
        ) }
      </OrderSummaries>
    </Box> }

    { !submitted && !cartsEmpty && <Box sx={{ p: 0 }}>
      <Box>
        { carts.map((cart) => <UserCart key={cart.id} cart={cart} /> ) }
      </Box>

      <Box pb={2}>
        <Box px={2}>
          <Text variant="h6">Order summary</Text>
        </Box>

        <Box display="flex" py={1} px={2}>
          <Text>Subtotal</Text>
          <Text ml="auto"><Currency value={subtotalCents / 100} /></Text>
        </Box>

        <Box display="flex" pb={1} px={2}>
          <Text>Tax</Text>
          <Text ml="auto"><Currency value={taxesCents / 100} /></Text>
        </Box>

        <Box display="flex" px={2} pt={1} sx={{ borderTop: '4px double grey'}}>
          <Text>Total</Text>
          <Text ml="auto"><Currency value={totalCents / 100} /></Text>
        </Box>

        <Box px={2} pt={2} textAlign="right">
          <Text variant="caption" color="GrayText">If applicable, any subsidies from your school will be applied after checkout.</Text>
        </Box>
      </Box>

      <Box px={2} pb={2}>
        <Text variant="h6">Payment Method</Text>
      </Box>

      <Box px={2} pb={2}>
        <PaymentMethodSelector
          paymentMethods={paymentMethods}
          value={paymentMethodId}
          disabled={loading}
          onChange={(evt) => setPaymentMethodId(evt.target.value)}
        />
      </Box>

      <Box px={2} pb={2}>
        <AddPaymentMethodModal {...stripeModal} />
        <Button onClick={stripeModal.openModal} fullWidth={false} disabled={loading} color="secondary">Add payment method</Button>
      </Box>
    </Box> }

    { !submitted && !cartsEmpty && <Box sx={{ px: 2, py: 2 }}>
      { errors?.map((error) => <Box key={error.message} py={1}>
        <Text color="error">{ error.message }</Text>
      </Box>) }

      <Button
        variant="contained"
        disabled={!paymentMethodId || paymentMethodId === '' || !cartsValid || loading || totalQty === 0}
        loading={loading}
        onClick={() => checkoutCarts()}
      >
        <Text>Submit Order</Text>
      </Button>
    </Box> }

    { !submitted && cartsEmpty && <Box sx={{ px: 2, py: 2 }}>
      <EmptyState icon={CartIcon} message="Your cart is empty." />
      <Button component={RouterLink} to="/menus">Go to menu</Button>
    </Box> }
  </Page>

  return <Dialog open={true} fullScreen={true}>
    <DialogTitle display="flex" flexWrap="nowrap" alignItems="center" gap={1} sx={(theme) => ({
      px: { xs: 1, md: 2 },
      alignItems: { md: 'center' },
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderBottomColor: theme.palette.divider,
    })}>
      <Box>
        <IconButton onClick={() => navigateTo(-1)}><ArrowBack /></IconButton>
      </Box>

      <Box>
        <Text variant="h6">Checkout</Text>
      </Box>

      { isMedium && <Box ml="auto">
        <IconButton onClick={() => navigateTo(-1)}><CloseIcon /></IconButton>
      </Box> }
    </DialogTitle>

    { submitted && orders.length > 0 && <DialogContent sx={{ p: 0 }}>
      <Page unpadded>
        <Box my={4} px={2}>
          <Text pb={2} variant="h3">Thank you, {me.preferredName || me.firstName}!</Text>
          <Text pb={2} variant="h6" fontWeight="normal">Your orders have been placed.</Text>
          <Text pb={2} variant="h6" fontWeight="normal">If you want to review your orders you can <Link component={RouterLink} to="/orders" replace>check your order history here</Link>.</Text>

          <Button component={RouterLink} to="/menus">Go to menu</Button>
        </Box>

        <OrderSummaries>
          { Object.keys(groupedOrders).flatMap((groupDate) =>
            groupedOrders[groupDate].map((order) => <OrderSummary key={order.id} order={order} interactive={false} />)
          ) }
        </OrderSummaries>
      </Page>
    </DialogContent> }

    { !submitted && <DialogContent sx={{ p: 0 }}>
      <Page unpadded>
        <Box mt={4}>
          { carts.map((cart) => <UserCart key={cart.id} cart={cart} /> ) }
        </Box>

        <Box pb={2}>
          <Box px={2}>
            <Text variant="h6">Order summary</Text>
          </Box>

          <Box display="flex" py={1} px={2}>
            <Text>Subtotal</Text>
            <Text ml="auto"><Currency value={subtotalCents / 100} /></Text>
          </Box>

          <Box display="flex" pb={1} px={2}>
            <Text>Tax</Text>
            <Text ml="auto"><Currency value={taxesCents / 100} /></Text>
          </Box>

          <Box display="flex" px={2} pt={1} sx={{ borderTop: '4px double grey'}}>
            <Text>Total</Text>
            <Text ml="auto"><Currency value={totalCents / 100} /></Text>
          </Box>

          <Box px={2} pt={2} textAlign="right">
            <Text variant="caption" color="GrayText">If applicable, any subsidies from your school will be applied after checkout.</Text>
          </Box>
        </Box>

        <Box px={2} pb={2}>
          <Text variant="h6">Payment Method</Text>
        </Box>

        <Box px={2} pb={2}>
          <PaymentMethodSelector
            paymentMethods={paymentMethods}
            value={paymentMethodId}
            disabled={loading}
            onChange={(evt) => setPaymentMethodId(evt.target.value)}
          />
        </Box>

        <Box px={2} pb={2}>
          <AddPaymentMethodModal {...stripeModal} />
          <Button onClick={stripeModal.openModal} fullWidth={false} disabled={loading} color="secondary">Add payment method</Button>
        </Box>
      </Page>
    </DialogContent> }

    { !submitted && <DialogActions sx={{ display: 'block', px: 0 }}>
      <Page unpadded sx={{ pl: 1, pr: 2 }}>
        { errors?.map((error) => <Box key={error.message} py={1}>
          <Text color="error">{ error.message }</Text>
        </Box>) }

        <Button
          variant="contained"
          disabled={!paymentMethodId || !cartsValid || loading || totalQty === 0}
          loading={loading}
          onClick={() => checkoutCarts()}
        >
          <Text>Submit Order</Text>
        </Button>
      </Page>
    </DialogActions> }
  </Dialog>
}

export default Checkout
