import lightOrange from './lightOrange'
import darkOrange from './darkOrange'

export const orange = {
  50: '#fff8e1',
  100: '#ffedb4',
  200: '#ffe184',
  300: '#ffd752',
  400: '#ffcc2d',
  500: '#ffc313',
  600: '#ffb50c',
  700: '#ffa20a',
  800: '#ff9108',
  900: '#ff7206', // primary
  A100: '#ffedb4',
  A200: '#ffe184',
  A400: '#ffcc2d',
  A700: '#ffa20a',
}

export const palette = {
  main: orange[900],
  // light: lightOrange[500],
  // dark: darkOrange[900],
  // contrastText: '#ffffff',
}

export { lightOrange, darkOrange }

export default orange
