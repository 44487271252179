import React from 'react'
import MuiButton, { ButtonProps } from '@mui/material/Button'
import { Spinner } from '../Loading'

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    campus: true
    kitchen: true
    kiosk: true
    luxo: true
    market: true
    tools: true
  }
  // EXAMPLE: Defining a variant="whatever" prop for buttons - see also /src/theme/index.ts for theme definition example
  // interface ButtonPropsVariantOverrides {
  //   whatever: true
  // }
}

export type ExtendedButtonProps<C extends React.ElementType = 'button'> = ButtonProps<C, { component?: C }> & {
  loading?: boolean;
  showLoadingLabel?: boolean;
};

export const Button = <C extends React.ElementType = 'button'>(props: ExtendedButtonProps<C>) => {
  const { loading, showLoadingLabel, ...buttonProps } = props
  const { children } = buttonProps
  return !loading
    ? (<MuiButton {...buttonProps} />)
    : (
      <MuiButton {...buttonProps} disabled={loading}>
        <Spinner
          size={16}
          message={showLoadingLabel ? children : null}
          sx={({ palette }) => {
            return { color: palette.primary.main }
          } }
          inline={showLoadingLabel ? 'center' : null}
        />
      </MuiButton>
    )
}

Button.defaultProps = {
  variant: 'contained',
  color: 'primary',
  fullWidth: true,
  showLoadingLabel: true,
  loading: false,
  disableRipple: true,
}

export default Button
