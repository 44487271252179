import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import {
  Box,
  Button,
  Text,
  RadioFilter,
} from 'components'
import {
  FiltersPopover,
  FiltersPopoverHeader,
  FiltersPopoverContent,
  FiltersPopoverFooter,
} from 'components/Filters'
import { usePopoverState, useViewport } from 'hooks'
import { ArrowDropDown, LocationOn } from '@mui/icons-material'
import { FilterState, FilterProps, useContext } from '@market/hooks/useFilteredAvailableMenuProducts'

export type MenuFiltersProps = Partial<FilterProps> & {
  filters: FilterState
}

export const LocationFilter: React.FC = () => {
  const { isMedium } = useViewport()
  const [domReady, setDomReady] = useState(false)
  const { filters: { filters, filterDefinitions, appliedFilters, availableLocations, setAndApplyFilters } } = useContext()
  const { open, anchorElement, handleClick, handleClose } = usePopoverState()
  const appliedFiltersCount = useMemo(() => {
    return appliedFilters.locationIds.length
  }, [appliedFilters])

  const setAndClose = useCallback((state: Partial<FilterState>) => {
    setAndApplyFilters(state)
    handleClose()
  }, [setAndApplyFilters, handleClose])

  useEffect(() => {
    setDomReady(true)
  }, [])

  return domReady && createPortal(<Box minWidth={0}>
    <Button onClick={handleClick} variant="text" fullWidth sx={(theme) => ({
        ml: 1,
        pl: 1,
        borderLeftWidth: '1px',
        borderLeftStyle: 'solid',
        borderLeftColor: theme.palette.divider,
      })}>
        <LocationOn />
        { appliedFiltersCount === 0 && <Text ml={1}>All Campuses</Text> }
        { appliedFiltersCount > 0 && <>
          <Text ml={1} component="span" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">{ availableLocations.find((loc) => appliedFilters.locationIds.includes(loc.id))?.name }</Text>
          { appliedFiltersCount > 1 && <Text fontWeight="bold" ml={1}>(+{ appliedFiltersCount - 1 })</Text> }
        </> }
        <ArrowDropDown />
      </Button>

    { open && <FiltersPopover
      open={open}
      anchorEl={anchorElement}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      sx={{ marginTop: { md: 1 } }}
    >
      <FiltersPopoverHeader onClose={handleClose}>
        <Text variant="h6">Campus</Text>
      </FiltersPopoverHeader>

      <FiltersPopoverContent>
        <RadioFilter filterName="locationIds" filterDefinitions={filterDefinitions} filters={filters} setFilters={setAndClose} hideLabel />
      </FiltersPopoverContent>

      <FiltersPopoverFooter sx={(theme) => ({
        display: 'flex',
        gap: 1,
        [theme.breakpoints.up('xs')]: {
          flexDirection: 'column',
        },
        [theme.breakpoints.up('md')]: {
          display: 'none',
          flexDirection: 'row',
        },
      })}>
        <Box width={isMedium ? undefined : '100%'}>
          <Button fullWidth={!isMedium} variant="outlined" onClick={handleClose}>Cancel</Button>
        </Box>
      </FiltersPopoverFooter>
    </FiltersPopover> }
  </Box>, document.getElementById('MarketNavBarPortal'))
}

export default LocationFilter
