import React, { useMemo } from 'react'
import { Select, SelectProps } from 'components/Select'
import { StripePaymentMethod } from '@market/graphql/schema/graphql'

export type PaymentMethodSelectorProps = SelectProps<string> & {
  paymentMethods: StripePaymentMethod[]
}

export const PaymentMethodSelector: React.FC<PaymentMethodSelectorProps> = ({ paymentMethods, label="Payment method", ...props }) => {
  const options = useMemo(() => paymentMethods.map((paymentMethod) => {
    return { label: paymentMethod.displayName, value: paymentMethod.stripeId }
  }), [paymentMethods])

  return <Select options={options} label={label} {...props} />
}

export default PaymentMethodSelector
