import React from 'react'
import { Box } from 'components'
import { NavBar as AppNavBar, NavBarProps } from 'compositions/NavBar'
import OrdoLogo from 'theme/ordo-logo.svg'

export const NavBar: React.FC<NavBarProps> = (props) => <AppNavBar {...props} logo={<OrdoLogo height="47" />}>
  <Box id="MarketNavBarPortal" display="flex" flexWrap="nowrap">
    {/* Portal for rendering filters, etc. on mobile */}
  </Box>
</AppNavBar>

export default NavBar