import { useMemo } from 'react'
import { DateTime } from 'luxon'
import { QueryHookOptions } from '@apollo/client'
import type { AvailableMenuProductsQueryQueryVariables, AvailableMenuProductsPayload } from '@market/graphql/schema/graphql'
import { availableMenuProductsQuery } from '@market/graphql/queries'
import { useMarketQuery } from './useMarketQuery'

type AvailableMenuProductsArgs = AvailableMenuProductsQueryQueryVariables & QueryHookOptions

export const useAvailableMenuProducts = ({ locationId, startDate, endDate, pollInterval }: AvailableMenuProductsArgs) => {
  const queryResult = useMarketQuery<{ availableMenuProducts: AvailableMenuProductsPayload }, AvailableMenuProductsQueryQueryVariables>(
    availableMenuProductsQuery,
    {
      variables: { locationId, startDate, endDate },
      pollInterval,
    }
  )

  const payload = useMemo(() => ({
    ...queryResult,
    loadedAt: DateTime.now(),
    data: {
      availableMenuProducts: {
        menuProducts: [],
        availabilityDates: [],
        fullAvailabilityDates: [],
        ...queryResult.data?.availableMenuProducts,
      },
      ...queryResult.data,
    }
  }), [queryResult])

  return payload
}

export default useAvailableMenuProducts
