import React, { useMemo } from 'react'
import {
  Box,
  IconButton,
  Text,
} from 'components'
import { Pencil as PencilIcon } from 'icons'
import { AccountUser } from '@market/graphql/schema/graphql'
import { useAccountUserModal } from '@market/hooks/useAccountUserModal'
import { useUpdateAccountUserForm } from '@market/hooks/useUpdateAccountUserForm'
import { useAccountContext } from '@market/hooks/useCurrentAccount'
import { AvatarIconImage } from '../AvatarIcon'
import { AccountUserModal } from '../AccountUserModal'
import { AccountUserForm } from '../AccountUserForm'

export interface AccountUserCardProps {
  accountUser: AccountUser
}

export const AccountUserCard: React.FC<AccountUserCardProps> = ({ accountUser }) => {
  const { user } = accountUser
  const { defaultLocation } = useAccountContext()
  const { open, openModal, closeModal } = useAccountUserModal({ accountUser })
  const [ mutationForm, { errors, loading, submitted } ] = useUpdateAccountUserForm(accountUser, defaultLocation, { onSuccess: () => closeModal() })
  const displayRole = useMemo(() => {
    if (accountUser.roles.includes('STUDENT')) {
      return 'STUDENT'
    } else if (accountUser.roles.includes('STAFF')) {
      return 'STAFF'
    } else if (accountUser.roles.includes('GUARDIAN')) {
      return 'PARENT'
    }
  }, [accountUser.roles])

  return <Box sx={{ cursor: 'pointer', width: '100%', minWidth: '100%' }}>
    <AccountUserModal accountUser={accountUser} open={open} onClose={() => closeModal()}>
      <AccountUserForm accountUser={accountUser} form={mutationForm} errors={errors} loading={loading} submitted={submitted} />
    </AccountUserModal>

    <Box display="flex" flexDirection="column" onClick={() => openModal()} sx={{ cursor: 'pointer', width: '100%', minWidth: '100%' }}>
      <Box position="relative" sx={{ width: '100%', height: 'auto', aspectRatio: '1/1' }}>
        <Box sx={{
            width: '100%',
            height: 'auto',
            aspectRatio: '1/1',
            borderRadius: '10px',
            overflow: 'hidden',
          }}
        >
          <AvatarIconImage name={user.preferredName || user.firstName} iconProps={{ sx: { fontSize: '124px' } }} />
        </Box>

        <Box position="absolute" bottom="8px" right="8px">
          <IconButton size="small" sx={(theme) => ({
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[3],
            '&:hover': {
              backgroundColor: theme.palette.background.paper,
              boxShadow: theme.shadows[5],
            }
          })}><PencilIcon fontSize="small" /></IconButton>
        </Box>
      </Box>

      <Box
        sx={(theme) => ({
          pt: 1,
          [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 2,
          },
        })}
      >
        <Box>
          <Text variant="h6" fontWeight="normal">{ user.displayName }</Text>
        </Box>

        <Box>
          <Text variant="subtitle2" fontWeight="normal" color="gray">{ displayRole }</Text>
        </Box>
      </Box>

      { (user.roster || accountUser.location) && <Box pt={1}>
        <Text variant="subtitle1" fontWeight="normal" display="inline">{ (user?.roster?.term?.location || accountUser.location).displayName }</Text>
        { user.roster && <Text variant="subtitle2" fontWeight="normal" color="gray" display="inline" pl={1}>{ user.roster.name }</Text> }
      </Box> }
    </Box>
  </Box>
}

export default AccountUserCard
