import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useField } from 'formik'
import {
  Alert,
  Autocomplete,
  Box,
  Form,
  FormControlLabel,
  FormSelect,
  FormSubmitButton,
  FormTelephoneField,
  RadioGroup,
  Radio,
  Page,
  Text,
  TextField,
} from 'components'
import { FormSelectProps } from 'components/Form/Elements/FormSelect'
import { useStatesProvinces } from 'hooks/useStatesProvinces'
import OrdoLogo from 'theme/ordo-logo.svg'
import { useServicedSchools, useUpdateAccountUserForm } from '@market/hooks'
import { UseAccountOnboardingWizard } from '@market/hooks/useAccountOnboardingWizard'
import { ListedSchool, StateProvince } from '@market/graphql/schema/graphql'
import { CircularProgress, styled } from '@mui/material'
import { debounce } from 'lodash'
import { SelectOptionObject } from 'components/Select/Select'

// TODO: Import these from the account user form instead
type Role = {
  role: 'GUARDIAN' | 'STUDENT' | 'STAFF'
  name: string
  description: string
}

const ROLES: Role[] = [
  {
    role: 'GUARDIAN',
    name: 'Parent',
    description: 'I need to order for my kids',
  },
  {
    role: 'STUDENT',
    name: 'Student',
    description: 'I need to order for myself',
  },
  {
    role: 'STAFF',
    name: 'Staff',
    description: 'I need to order for myself and/or my kids',
  },
]

const RoleRadioButton = styled(FormControlLabel)(({ theme, checked }) => ({
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: theme.palette.divider,
  borderRadius: '10px',
  padding: theme.spacing(2),
  gap: theme.spacing(2),
  marginRight: 0,
  marginLeft: 0,
  backgroundColor: checked ? theme.palette.divider : 'none',
  '&:hover': {
    backgroundColor: theme.palette.grey[200],
  },
}))

const RosterSelector: React.FC<FormSelectProps<string> & { locationId: string }> = ({ locationId, options, ...props }) => {
  const [ {}, roleMeta ] = useField('role')
  const [{ value: selectedRosterId }, {}, { setValue: setRoster }] = useField('rosterId')
  const [{ value: selectedLocationId }, {}, { setValue: setLocation }] = useField('locationId')

  useEffect(() => {
    if (locationId !== selectedLocationId || !options.find((opt: SelectOptionObject<string>) => opt.value === selectedRosterId)) {
      setLocation(locationId)
      setRoster('')
    }
  }, [locationId, options, selectedRosterId, selectedLocationId, setRoster, setLocation])

  if (roleMeta.value === 'GUARDIAN') return null

  return <Box>
    <FormSelect options={options} {...props} />
  </Box>
}

export const ProfileStep: React.FC<{ wizard: UseAccountOnboardingWizard }> = ({ wizard }) => {
  const { data: { statesProvinces }, loading: statesLoading } = useStatesProvinces()
  const [ loadServicedSchools, { data: { servicedSchools: loadedSchools }, previousData: { servicedSchools: previousSchools }, loading: schoolsLoading } ] = useServicedSchools()
  const servicedSchools = useMemo(() => schoolsLoading ? previousSchools : loadedSchools, [loadedSchools, previousSchools, schoolsLoading])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchServicedSchools = useCallback(debounce((evt) => {
    loadServicedSchools({ variables: { state: wizard.selectedState?.id, query: evt.target.value } })
  }, 300), [wizard.selectedState, loadServicedSchools])

  const [selectedRole, setSelectedRole] = useState<Role>(ROLES.find((role) => role.role === wizard.accountUser?.roles?.find((aRole) => ['GUARDIAN', 'STUDENT', 'STAFF'].includes(aRole))))

  const [ mutationForm, { errors, loading, submitted } ] = useUpdateAccountUserForm({
    ...wizard.accountUser,
    location: wizard.selectedSchool?.location,
    roles: [
      ...(wizard.accountUser?.roles || []),
      selectedRole?.role,
    ].filter((v, i, a) => a.indexOf(v) === i || !v)
  },
  wizard.defaultLocation,
  {
    onSuccess: () => wizard.navigateToNext()
  })

  const term = useMemo(() => {
    return wizard.selectedSchool?.terms[0]
  }, [wizard.selectedSchool])

  const rosters = useMemo(() => {
    return term?.rosters?.filter((roster) => roster.isVisible && !roster.isArchived)?.sort((a, b) => a.sortOrder - b.sortOrder) || []
  }, [term])

  useEffect(() => {
    loadServicedSchools({ variables: { state: wizard.selectedState?.id } })
  }, [wizard.selectedState, loadServicedSchools])

  return <Page fullWidth unpadded noMargins>
    <Box sx={{ width: { md: '600px' }, mx: { md: 'auto' } }}>
      <Box mb={2} textAlign="center">
        <OrdoLogo height="60" />
      </Box>

      <Text variant="h3" textAlign="center" mb={2}>{ wizard.activeStep.name }</Text>
      <Text textAlign="center" mb={2}>Enter your information to setup your account and family.</Text>

      <Box display="flex" gap={1}>
        <Box mb={2} flexGrow={1}>
          <Autocomplete
            fullWidth
            selectOnFocus
            disabled={statesLoading && statesProvinces.length === 0}
            disableClearable={true}
            onChange={(_e, value: StateProvince) => {
              wizard.setSelectedState(value)
              wizard.setSelectedSchool(null)
            }}
            value={wizard.selectedState}
            options={statesProvinces}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            getOptionLabel={(option: StateProvince) => option.name}
            renderInput={(params) => <TextField {...params} variant="filled" label="State" placeholder="Select your state" />}
          />
        </Box>

        <Box mb={2} flexGrow={4}>
          <Autocomplete
            fullWidth
            selectOnFocus
            disabled={!wizard.selectedState}
            autoComplete={true}
            disableClearable={true}
            onChange={(_e, value: ListedSchool) => wizard.setSelectedSchool(value)}
            value={wizard.selectedSchool}
            options={servicedSchools}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            getOptionLabel={(option: ListedSchool) => option.name}
            noOptionsText={schoolsLoading ? "Searching..." : "No matching schools"}
            filterOptions={(x) => x}
            renderInput={(params) => <TextField
              {...params}
              variant="filled"
              label={<>School campus {schoolsLoading ? <CircularProgress color="primary" size={12} /> : null}</>}
              placeholder="Find your school"
              onChange={searchServicedSchools}
              InputProps={{
                ...params.InputProps,
                // endAdornment: (
                //   <React.Fragment>
                //     {/* {schoolsLoading ? <CircularProgress color="secondary" size={20} /> : null} */}
                //     {params.InputProps.endAdornment}
                //   </React.Fragment>
                // ),
              }}
            />}
          />
        </Box>
      </Box>

      { !selectedRole && <Box>
        <Text mb={2}>I am a...</Text>
        <RadioGroup sx={{ gap: 2 }}>
          { ROLES.map((role) =>
            <RoleRadioButton
              key={role.role}
              label={<Box>
                <Text fontWeight="bold">{ role.name }</Text>
                <Text>{ role.description }</Text>
              </Box>}
              control={<Radio
                checked={role.role === selectedRole?.role}
                onChange={(_e, checked) => {
                  if (checked) setSelectedRole(role)
                }}
              />}
            />
          ) }
        </RadioGroup>
      </Box> }

      { selectedRole && <Form form={mutationForm}>
        <Box>
          <FormSelect
            id="role"
            name="role"
            label="I am a..."
            options={ROLES.map((role) => ({ label: role.name, value: role.role }))}
          />
        </Box>

        <RosterSelector
          id="rosterId"
          name="rosterId"
          label={rosters[0]?.groupName}
          placeholder={rosters[0]?.groupName}
          options={rosters.map((roster) => ({ value: roster.id, label: roster.displayName }))}
          locationId={wizard.selectedSchool?.locationId}
        />

        <Box mb={2}>
          <FormTelephoneField
            id="phoneNumber"
            name="phoneNumber"
            label="Cell phone"
            placeholder="Cell phone"
          />
          <Text variant="caption">Your number will only be used for order reminders and account support. We will not spam you or share this information.</Text>
        </Box>

        <Box>
          { !submitted && <>{ errors.map((error) => <Alert key={`${error.code}:${error.message}`} severity="error" sx={{ width: '100%', mb: 2 }}>
            {error.message}
          </Alert>)}</> }

          <FormSubmitButton loading={loading}>
            Continue
          </FormSubmitButton>
        </Box>
      </Form> }
    </Box>
  </Page>
}

export default ProfileStep
