export const lightBlue = {
  50: '#e2ebff', // primary
  100: '#b9cdf5',
  200: '#8dace7',
  300: '#5c8cd9',
  400: '#2973d0',
  500: '#005bc7',
  600: '#0052bd',
  700: '#0049b0',
  800: '#003fa4',
  900: '#002d90',
  A100: '#b9cdf5',
  A200: '#8dace7',
  A400: '#2973d0',
  A700: '#0049b0',
}

export default lightBlue
