import React, { PropsWithChildren } from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import MenuIcon from '@mui/icons-material/Menu'
import useMe from 'hooks/useMe'
import { Box, Button } from 'components'

export type NavBarProps = React.PropsWithChildren<{
  toggleDrawer: (e: React.MouseEvent) => void
}>

export type NavBarLogoProps = { logo: React.ReactElement }

export const NavBar: React.FC<PropsWithChildren<NavBarProps & NavBarLogoProps>> = ({ children, logo, toggleDrawer }) => {
  const { token } = useMe()

  return <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, display: token ? 'block' : 'none' }}>
    <Toolbar sx={{
      display: 'flex',
      alignItems: 'center',
      px: { xs: 1, sm: 2 }
    }}>
      <Box display="flex">
        <Button color="inherit" variant="text" onClick={toggleDrawer} fullWidth={false} sx={{
          px: 0,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}>
          <MenuIcon fontSize='large' sx={{ mr: 1 }} />
          { logo }
        </Button>
      </Box>

      <Box minWidth={0}>
        { children }
      </Box>

      <Box display="flex" justifySelf="flex-end" justifyContent="flex-end" ml="auto">
        <Box id="NavBarPortal" display="flex" flexWrap="nowrap">
          {/* Portal for rendering filters, etc. on mobile */}
        </Box>
      </Box>
    </Toolbar>
  </AppBar>
}

export default NavBar
