import React from 'react'
import MenuItem from '@mui/material/MenuItem'
import { MenuLink } from 'components'
import { useMe, useLoginUrl } from 'hooks'
import { Market as MarketIcon } from 'icons'

export type MarketLinkProps = {
  me: ReturnType<typeof useMe>
  onClick?: React.MouseEventHandler
}

export const MarketLink: React.FC<MarketLinkProps> = ({ me, onClick }) => {
  const marketLoginUrl = useLoginUrl('market')

  if (!me.hasMarketAccess) return null

  return <MenuItem onClick={onClick}>
    <MenuLink href={marketLoginUrl} sx={(theme) => ({ fontWeight: 'bold', color: theme.palette.market.dark })}>
      <MarketIcon sx={{ marginRight: 1 }} />
      Order Meals
    </MenuLink>
  </MenuItem>
}

export default MarketLink
