export const lightMagenta = {
  50: '#ffe1ed', // primary
  100: '#f9b6d1',
  200: '#f287b2',
  300: '#eb5693',
  400: '#e62d7a',
  500: '#e20063',
  600: '#d10060',
  700: '#bb005a',
  800: '#a60056',
  900: '#81004e',
  A100: '#f9b6d1',
  A200: '#f287b2',
  A400: '#e62d7a',
  A700: '#bb005a',
}

export default lightMagenta
