import lightYellow from './lightYellow'
import darkYellow from './darkYellow'

export const yellow = {
  50: '#fffde5',
  100: '#fff9c0',
  200: '#fff596',
  300: '#fff06a',
  400: '#fdeb44',
  500: '#fbe60d',
  600: '#ffd812', // primary
  700: '#febf05',
  800: '#fea600',
  900: '#fc7a00',
  A100: '#fff9c0',
  A200: '#fff596',
  A400: '#fdeb44',
  A700: '#febf05',
}

export const palette = {
  main: yellow[600],
  // light: lightYellow[100],
  // dark: darkYellow[800],
  // contrastText: '#ffffff',
}

export { lightYellow, darkYellow }

export default yellow
