import React from 'react'
import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Text, Link, Card, CardContent, Button } from 'components'
import { Check as CheckIcon, Ignore as IgnoreIcon } from 'icons'
import { TaskWithComponent } from './Todo'
import { TodosWizard } from 'hooks/useTodosWizard'

export interface TodoCardProps {
  todo: TaskWithComponent
  wizard: TodosWizard
}

const TodoIcon: React.FC<Pick<TodoCardProps, 'todo'> & { fontSize?: "small" | "medium" | "large" | "inherit"}> = ({ todo, fontSize }) => {
  const TodoIconComponent = todo.icon

  if (todo.status === "completed") {
    return <CheckIcon fontSize={fontSize} />
  } else if (todo.status === "ignored") {
    return <IgnoreIcon fontSize={fontSize} />
  } else if (todo.resolved) {
    return <CheckIcon fontSize={fontSize} />
  } else {
    return <TodoIconComponent fontSize={fontSize} />
  }
}

export const TodoCard: React.FC<TodoCardProps> = ({ todo, wizard }) => {
  return <Card sx={(theme) => ({ mb: 2, display: 'flex', flexDirection: 'column', borderColor: theme.palette.divider })}>
    <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
      <Box display="flex" flexDirection="column" flexGrow={1}>
        <Box display="flex" pb={1} justifyContent="space-between">
          <Box>
            <Link to={`/todos/${todo.id}`} component={RouterLink} sx={(theme) => ({
              fontWeight: 'normal',
              color: theme.palette.primary.main,
              ...(todo.status === "ignored" && {
                color: '#ccc',
              }),
              ...(todo.resolved && {
                color: theme.palette.success.light,
              }),
              ...(todo.status === "completed" && {
                color: theme.palette.success.main,
              }),
            })}>
              <Box display="flex">
                <Text variant="h6">
                  <TodoIcon todo={todo} fontSize="large" />
                </Text>
                <Text variant="h6" ml={2}>
                  {todo.name}
                </Text>
              </Box>

              { todo.summary && <Box>
                <Markdown rehypePlugins={[rehypeRaw]}>{ todo.summary }</Markdown>
              </Box> }
            </Link>
          </Box>

          {/* <Box>
            <Chip
              sx={{ ml: 1 }}
              size="small"
              label={todo.resolved && todo.status === "incomplete" ? "ready to complete" : todo.status}
              icon={(todo.status === "completed" || todo.resolved) ? (<CheckIcon />) : (todo.status === "ignored" ? <IgnoreIcon /> : <IncompleteIcon />)}
              color={(todo.status === "completed" || todo.resolved) ? "success" : undefined}
            />
          </Box> */}
        </Box>

        <Box flexGrow={1}>
          <Box textAlign="right" mt={3}>
            {/* { !todo.definition.required && !todo.resolved && todo.status !== "ignored" && <Button
              onClick={() => wizard.ignore(todo, { navigateToNext: false })}
              disabled={todo.resolved}
              color="inherit"
              size="medium"
              variant="outlined"
              fullWidth={false}
              sx={{ ml: 1 }}
            >
              <TodoIcon todo={todo} /> <Text pl={1}>Ignore task</Text>
            </Button> } */}

            { todo.status !== "completed" && <Button
              onClick={() => wizard.navigateToTodo(todo)}
              color={todo.status === "ignored" ? "inherit" : "primary"}
              size="medium"
              // variant="outlined"
              fullWidth={false}
              sx={{ ml: 1 }}
            >
              <TodoIcon todo={todo} /> <Text pl={1}>View task</Text>
            </Button> }

            { todo.resolved && todo.status === "incomplete" && <Button
              onClick={() => wizard.resolve(todo, { navigateToNext: false })}
              disabled={!todo.resolved}
              color="success"
              size="medium"
              // variant="outlined"
              fullWidth={false}
              sx={{ ml: 1 }}
            >
              <TodoIcon todo={todo} /> <Text pl={1}>Complete task</Text>
            </Button> }
          </Box>

        </Box>
      </Box>
    </CardContent>
  </Card>
}

export default TodoCard
