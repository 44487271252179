import React, { useCallback, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  Text,
} from 'components'
import usePauseSubscription from '@market/hooks/usePauseSubscription'
import { Subscription } from '@market/graphql/schema/graphql'
import { DateTime } from 'luxon'

export type PauseSubscriptionDialogProps = {
  open: boolean
  onClose: () => void
  subscription: Subscription
}

export const PauseSubscriptionDialog: React.FC<PauseSubscriptionDialogProps> = ({ subscription, open, onClose }) => {
  const pauseDates = [1, 2, 3, 4].map((weeks) => {
    const date = DateTime.now().startOf('day').plus({ weeks: weeks }).startOf('week')
    return { label: `${weeks} Weeks (${date.toFormat('ccc, LLL d')})`, value: date.toFormat('yyyy-LL-dd') }
  })
  const [ pauseUntil, setPauseUntil ] = useState(pauseDates[0].value)
  const [ pauseSubscription, { loading: pauseLoading } ] = usePauseSubscription()

  const handleClickPause = useCallback(() => {
    pauseSubscription({ variables: { subscriptionId: subscription.id, pauseUntil: pauseUntil } })
    onClose()
  }, [subscription, pauseUntil, pauseSubscription, onClose])

  return <Dialog open={open} onClose={onClose}>
    <DialogTitle component={Box}>
      Pause subscription
    </DialogTitle>

    <DialogContent>
      <Text>You can pause an active subscription for up to 4 weeks at a time. How long would you like to pause this subscription?</Text>

      <Box mt={4}>
        <Select
          label="Pause until"
          options={pauseDates}
          onChange={(evt) => setPauseUntil(evt.target.value as string)}
          value={pauseUntil}
          
        />
      </Box>
    </DialogContent>

    <DialogActions>
      <Button color="warning" loading={pauseLoading} disabled={pauseLoading} onClick={handleClickPause}>Pause subscription</Button>
      <Button onClick={onClose}>Cancel</Button>
    </DialogActions>
  </Dialog>
}

export default PauseSubscriptionDialog