import React, { useCallback, useEffect, useMemo } from 'react'
import { Link as RouterLink, useSearchParams } from 'react-router-dom'
import { debounce } from 'lodash'
import TextField from '@mui/material/TextField'
import {
  Autocomplete,
  Box,
  Button,
  Link,
  Page,
  Text,
} from 'components'
import { useStatesProvinces } from 'hooks/useStatesProvinces'
import { useListedSchools } from 'hooks/useListedSchools'
import OrdoLogo from 'theme/ordo-logo.svg'
import { UseRegistrationWizard } from '@market/hooks/useRegistrationWizard'
import { ListedSchool, StateProvince } from '@market/graphql/schema/graphql'
import { CircularProgress } from '@mui/material'

export const SelectCampusStep: React.FC<{ wizard: UseRegistrationWizard }> = ({ wizard }) => {
  const { data: { statesProvinces }, loading: statesLoading } = useStatesProvinces()
  const [ loadListedSchools, { data: { listedSchools: loadedSchools }, previousData: { listedSchools: previousSchools }, loading: schoolsLoading } ] = useListedSchools()
  const listedSchools = useMemo(() => schoolsLoading ? previousSchools : loadedSchools, [loadedSchools, previousSchools, schoolsLoading])
  const [ params ] = useSearchParams()
  const paramsState = useMemo(() => params.get('state'), [params])
  const paramsLocationId = useMemo(() => params.get('locationId'), [params])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchListedSchools = useCallback(debounce((evt) => {
    loadListedSchools({ variables: { state: wizard.selectedState?.id, query: evt.target.value } })
  }, 300), [wizard.selectedState, loadListedSchools])

  useEffect(() => {
    loadListedSchools({ variables: { state: wizard.selectedState?.id, locationId: paramsLocationId } })
  }, [wizard.selectedState, paramsLocationId, loadListedSchools])

  useEffect(() => {
    if (!!paramsState) {
      const selectedState = statesProvinces.find((state) => state.abbreviation === paramsState)
      wizard.setSelectedState(selectedState)
    }
    
    if (!!paramsLocationId) {
      const selectedLocation = loadedSchools.find((location) => location.locationId === paramsLocationId)
      wizard.setSelectedSchool(selectedLocation as ListedSchool)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsState, paramsLocationId, statesProvinces, loadedSchools, wizard.setSelectedState, wizard.setSelectedSchool])

  return <Page fullWidth unpadded noMargins>
    <Box sx={{ width: { md: '600px' }, mx: { md: 'auto' } }}>
      <Box mb={2} textAlign="center">
        <Link component={RouterLink} to="/"><OrdoLogo height="60" /></Link>
      </Box>

      <Text variant="h3" textAlign="center" mb={2}>{ wizard.activeStep.name }</Text>
      <Text textAlign="center">Enter your state and school name.</Text>

      <Box textAlign="center">
        <Box my={2}>
          <Autocomplete
            fullWidth
            selectOnFocus
            disabled={statesLoading && statesProvinces.length === 0}
            disableClearable={true}
            onChange={(_e, value: StateProvince) => {
              wizard.setSelectedState(value)
              wizard.setSelectedSchool(null)
            }}
            value={wizard.selectedState}
            options={statesProvinces}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            getOptionLabel={(option: StateProvince) => option.name}
            renderInput={(params) => <TextField {...params} label="State" placeholder="Select your state" />}
          />
        </Box>

        <Box my={2}>
          <Autocomplete
            fullWidth
            selectOnFocus
            disabled={!wizard.selectedState}
            autoComplete={true}
            disableClearable={true}
            onChange={(_e, value: ListedSchool) => wizard.setSelectedSchool(value)}
            value={wizard.selectedSchool}
            options={listedSchools}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            getOptionLabel={(option: ListedSchool) => option.name}
            noOptionsText={schoolsLoading ? "Searching..." : "No matching schools"}
            filterOptions={(x) => x}
            renderInput={(params) => <TextField
              {...params}
              label={<>School campus { schoolsLoading ? <CircularProgress color="primary" size={12} /> : null }</>}
              placeholder="Find your school"
              onChange={searchListedSchools}
              InputProps={{
                ...params.InputProps,
                // endAdornment: (
                //   <React.Fragment>
                //     {/* {schoolsLoading ? <CircularProgress color="secondary" size={20} /> : null} */}
                //     {params.InputProps.endAdornment}
                //   </React.Fragment>
                // ),
              }}
            />}
          />
        </Box>

        <Box my={2}>
          <Button
            disabled={!wizard.selectedState || !wizard.selectedSchool}
            onClick={() => wizard.navigateToNext()}
          >Continue</Button>
        </Box>
      </Box>

      <Text textAlign="center">Already have an account? <Link component={RouterLink} to="/login">Sign in</Link></Text>
    </Box>
  </Page>
}

export default SelectCampusStep
