import { archiveAccountUserMutation } from '@market/graphql/mutations'
import { MutationTuple, useMarketMutation } from './useMarketMutation'
import type { ArchiveAccountUserInput, ArchiveAccountUserPayload } from '@market/graphql/schema/graphql'

export type ArchiveAccountUserMutationData = { archiveAccountUser: ArchiveAccountUserPayload }

export const useArchiveAccountUser: (variables?: ArchiveAccountUserInput) => MutationTuple<ArchiveAccountUserPayload, ArchiveAccountUserInput> = (variables)  => {
  const [ archiveAccountUserFn, { data, ...result } ] = useMarketMutation<ArchiveAccountUserPayload, ArchiveAccountUserInput>(
    archiveAccountUserMutation,
    {
      variables,
      // notifyOnNetworkStatusChange: true
    }
  )

  return [ archiveAccountUserFn, { ...result, data } ]
}

export default useArchiveAccountUser
